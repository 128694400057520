import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {Backend} from './Backend';

export class NoOpBackend implements Backend {
  public sendRequest(sample: Sample) {}
  public sendUnloadRequest(sample: Sample) {}
  public sendRequestSynchronous(sample: Sample) {}
  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {}
}
