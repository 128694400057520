export class AdCallbacks {
  public onAdStarted = (event: any) => {};
  public onAdFinished = (event: any) => {};
  public onAdBreakStarted = (event: any) => {};
  public onAdBreakFinished = (event: any) => {};
  public onAdClicked = (event: any) => {};
  public onAdError = (event: any) => {};
  public onAdManifestLoaded = (event: any) => {};
  public onAdQuartile = (event: any) => {};
  public onAdSkipped = (event: any) => {};
  public onPlay = (issuer?: string) => {};
  public onPause = (issuer?: string) => {};
  public onBeforeUnload = () => {};
}

export interface AdModuleAPI {
  readonly adCallbacks: AdCallbacks;
  isLinearAdActive: () => boolean;
  getContainer: () => HTMLElement;
  getAdModuleInfo: () => {name?: string; version?: string};
  currentTime: () => number;
}
