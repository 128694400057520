import {ErrorCode} from '../enums/ErrorCode';
import {Event} from '../enums/Event';
import {VideoStartFailedReason} from '../enums/VideoStartFailedReason';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import ErrorEventObject from '../types/ErrorEventObject';
import * as ErrorData from '../types/EventData';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import * as Settings from '../utils/Settings';
import * as Utils from '../utils/Utils';

const REBUFFERING_HEARTBEAT_INTERVALS = [3000, 5000, 10000, 30000, 59700];

export abstract class AnalyticsStateMachine {
  protected stateMachine: StateMachine.StateMachine & {[key: string]: any};
  protected onEnterStateTimestamp: number = 0;

  protected readonly videoStartupTimeoutMs: number = Settings.ANALYTICS_VIDEOSTART_TIMEOUT;
  protected readonly rebufferTimeoutMs: number = Settings.ANALYTICS_REBUFFER_TIMEOUT;

  private rebufferingHeartbeatIntervalHandle?: number;
  private currentRebufferingIntervalIndex: number = 0;
  private rebufferingTimeoutHandle?: number;
  private videoStartTimeout?: number;

  constructor(protected stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    this.stateMachine = this.createStateMachine(opts);
  }

  public abstract createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine.StateMachine;
  public abstract callEvent(eventType: string, eventObject: any, timestamp: number): void;
  public abstract sourceChange(config: any, timestamp: number, currentTime?: number): void;

  //#region rebuffer timeout

  protected startRebufferingHeartbeatInterval(reset: boolean = true) {
    this.resetRebufferingHelpers(reset);

    this.startRebufferingTimeoutHandle();

    this.rebufferingHeartbeatIntervalHandle = window.setInterval(() => {
      if (this.stateMachine.current.toLowerCase() !== 'rebuffering') {
        this.resetRebufferingHelpers();
        return;
      }
      const timestamp = new Date().getTime();
      const stateDuration = timestamp - this.onEnterStateTimestamp;
      this.stateMachineCallbacks.heartbeat(stateDuration, this.stateMachine.current.toLowerCase(), {
        buffered: stateDuration,
      });
      this.onEnterStateTimestamp = timestamp;
      this.currentRebufferingIntervalIndex = Math.min(
        this.currentRebufferingIntervalIndex + 1,
        REBUFFERING_HEARTBEAT_INTERVALS.length - 1
      );
      this.startRebufferingHeartbeatInterval(false);
    }, REBUFFERING_HEARTBEAT_INTERVALS[this.currentRebufferingIntervalIndex]);
  }

  protected resetRebufferingHelpers(reset: boolean = true) {
    if (reset) {
      this.currentRebufferingIntervalIndex = 0;
    }
    this.clearRebufferingHeartbeatHandle();
    this.clearRebufferingTimeoutHandle(reset);
  }

  protected clearRebufferingHeartbeatHandle() {
    if (this.rebufferingHeartbeatIntervalHandle != null) {
      window.clearInterval(this.rebufferingHeartbeatIntervalHandle);
      this.rebufferingHeartbeatIntervalHandle = undefined;
    }
  }

  protected startRebufferingTimeoutHandle() {
    if (this.currentRebufferingIntervalIndex > 0) {
      return;
    }
    this.rebufferingTimeoutHandle = window.setTimeout(() => {
      this.callEvent(Event.ERROR, new ErrorEventObject(ErrorCode.BufferingTimeoutReached), Utils.getCurrentTimestamp());
      this.stateMachineCallbacks.stop_collecting();
    }, this.rebufferTimeoutMs);
  }

  protected clearRebufferingTimeoutHandle(reset: boolean) {
    if (reset && this.rebufferingTimeoutHandle != null) {
      window.clearTimeout(this.rebufferingTimeoutHandle);
      this.rebufferingTimeoutHandle = undefined;
    }
  }
  //#endregion

  //#region videoStartupFailed

  protected getVideoStartupFailedEventData(timestamp: number, event?: string, errorData?: any) {
    const reason = this.getReasonForVideoStartFailure(event);
    const eventData: ErrorData.VideoStartFailedEvent = {timestamp, reason};
    // only if the reason is Timeout the errorData is relevant
    // we expect it to be the ErrorCode.VideoStartupTimeoutReached error
    if (reason === VideoStartFailedReason.Timeout) {
      eventData.errorData = errorData;
    }
    return eventData;
  }

  protected getReasonForVideoStartFailure(event?: string) {
    switch (event) {
      case Event.ERROR:
        return VideoStartFailedReason.PlayerError;
      case Event.UNLOAD:
        return VideoStartFailedReason.PageClosed;
      case Event.VIDEOSTART_TIMEOUT:
        return VideoStartFailedReason.Timeout;
      default:
        return VideoStartFailedReason.Unknown;
    }
  }

  protected setVideoStartTimeout() {
    if (this.videoStartTimeout != null) {
      this.clearVideoStartTimeout();
    }
    this.videoStartTimeout = window.setTimeout(() => {
      this.callEvent(
        Event.VIDEOSTART_TIMEOUT,
        new ErrorEventObject(ErrorCode.VideoStartupTimeoutReached),
        Utils.getCurrentTimestamp()
      );
      this.stateMachineCallbacks.stop_collecting();
    }, this.videoStartupTimeoutMs);
  }

  protected clearVideoStartTimeout() {
    window.clearTimeout(this.videoStartTimeout);
    this.videoStartTimeout = undefined;
  }
  //#endregion
}
