(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["analytics"] = factory();
	else
		root["bitmovin"] = root["bitmovin"] || {}, root["bitmovin"]["analytics"] = factory();
})(window, function() {
return 