import {DownloadSpeedInfo} from '../types/DownloadSpeedInfo';
import {SpeedMeasurement} from '../types/SpeedMeasurement';
import {Converter} from '../utils/Converter';
import {Measure} from './Measure';

export class DownloadSpeedMeter {
  protected measures: Measure[];

  constructor() {
    this.measures = [];
  }

  public reset(): void {
    this.measures = [];
  }

  public addMeasurement(measurement: SpeedMeasurement): void {
    if (measurement.httpStatus >= 400) {
      return;
    }
    const measure = new Measure(measurement);

    const threshhold = Converter.bitsToBytes(300 * 1000 * 1000); // 300 Megabit per second in bytes
    if (measure.speed >= threshhold) {
      return;
    }

    this.measures.push(measure);
  }

  public getInfo(): DownloadSpeedInfo {
    return {
      segmentsDownloadCount: this.measures.length,
      segmentsDownloadSize: this.measures.map((m) => m.size).reduce(this.add, 0),
      segmentsDownloadTime: Math.ceil(this.totalTime() * 1000),
      avgDownloadSpeed: this.avgSpeed(),
      minDownloadSpeed: this.minSpeed(),
      maxDownloadSpeed: this.maxSpeed(),
      avgTimeToFirstByte: this.avgTimeToFirstByte(),
    };
  }

  private add(a: number, b: number): number {
    return a + b;
  }

  private avgSpeed(): number {
    if (this.measures.length === 0) {
      return 0;
    }
    const totalSpeed = this.speeds().reduce((a, b) => {
      return a + b;
    }, 0);
    const numMeasurements = this.measures.length;
    return this.bytePerSecondToBitPerSecond(totalSpeed / numMeasurements);
  }

  private bytePerSecondToBitPerSecond(bps: number): number {
    return Converter.bytesToBits(bps);
  }

  private minSpeed(): number {
    if (this.measures.length === 0) {
      return 0;
    }
    return this.bytePerSecondToBitPerSecond(Math.min(...this.speeds()));
  }

  private speeds(): number[] {
    return this.measures.map((m) => {
      return m.speed;
    });
  }

  private maxSpeed(): number {
    if (this.measures.length === 0) {
      return 0;
    }
    return this.bytePerSecondToBitPerSecond(Math.max(...this.speeds()));
  }

  private totalTime(): number {
    if (this.measures.length === 0) {
      return 0;
    }
    return this.measures.reduce((a, b) => {
      return a + b.duration;
    }, 0);
  }

  private avgTimeToFirstByte(): number {
    if (this.measures.length === 0) {
      return 0;
    }
    const avg =
      this.measures.reduce((a, b) => {
        return a + b.timeToFirstByte * 1000;
      }, 0) / this.measures.length;

    return Math.ceil(avg);
  }
}
