import {logger} from './Logger';

// tslint:disable-next-line
export function post(url: string, body: any, callback: Function, async: boolean = true): void {
  let xhttp: any;
  let legacyMode = false;

  if ((window as any).XDomainRequest) {
    legacyMode = true;
  }

  if (legacyMode) {
    xhttp = new (window as any).XDomainRequest();
  } else {
    xhttp = new XMLHttpRequest();
  }

  const responseCallback = () => {
    // tslint:disable-next-line
    if (xhttp.readyState == XMLHttpRequest.DONE) {
      if (xhttp.responseText <= 0) {
        return;
      }

      const sampleResponse = JSON.parse(xhttp.responseText);

      callback(sampleResponse);
    }
  };

  if (legacyMode) {
    xhttp.onload = responseCallback;
  } else {
    xhttp.onreadystatechange = responseCallback;
  }

  try {
    xhttp.open('POST', url, async);
    if (!legacyMode) {
      xhttp.setRequestHeader('Content-Type', 'text/plain');
    }
    xhttp.send(JSON.stringify(body));
  } catch (e) {
    logger.error('Failed to send POST request.', e);
  }
}
