/* tslint:disable:no-console */
class Logger {
  constructor(showLogs: boolean = false) {
    (this as any).showLogs = showLogs;
  }

  public setLogging(logging: boolean) {
    (this as any).showLogs = logging;
  }

  public isLogging() {
    return (this as any).showLogs;
  }

  public log(msg: string, ...optionalParamters: any[]) {
    if (!(this as any).showLogs) {
      return;
    }

    console.log(msg, ...optionalParamters);
  }

  public logTable(fields: any[]) {
    console.table(fields);
  }

  public errorMessageToUser(msg: string, ...optionalParamters: any[]) {
    console.error(msg, ...optionalParamters);
  }

  public error(msg: string | undefined, ...optionalParamters: any[]) {
    if (!(this as any).showLogs) {
      return;
    }

    console.error(msg, ...optionalParamters);
  }

  public warning(msg: string) {
    if (!(this as any).showLogs) {
      return;
    }

    console.warn(msg);
  }

  public warningMessageToUser(msg: string, ...optionalParameters: any[]) {
    console.warn(msg, ...optionalParameters);
  }
}

export const padRight = (str: string | undefined, length: number) => {
  const padStr = new Array(length).join(' ');
  return (str + padStr).slice(0, length);
};

export const logger = new Logger();
