import {Bitmovin7AnalyticsStateMachine} from '../../analyticsStateMachines/Bitmovin7AnalyticsStateMachine';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {getSourceInfoFromBitmovinSourceConfig} from '../../utils/BitmovinProgressiveSourceHelper';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class Bitmovin7InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private onBeforeUnLoadEvent: boolean = false;

  constructor(private player: any, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new Bitmovin7AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
  }

  public initialize() {
    this.register();
  }

  public getPlayerVersion = () => this.player.version;
  public getPlayerName = () => Player.BITMOVIN;
  public getPlayerTech = () => this.player.getPlayerType();
  public getAutoPlay(): boolean {
    if (this.player.getConfig().playback) {
      return this.player.getConfig().playback.autoplay || false;
    }
    return false;
  }
  public getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  public getCurrentPlaybackInfo(): PlaybackInfo {
    const sourceInfo: any = {};
    const source = this.player.getConfig().source;
    if (source) {
      const progSourceInfo = getSourceInfoFromBitmovinSourceConfig(source.progressive, this.player);
      sourceInfo.videoTitle = source.title;
      sourceInfo.mpdUrl = source.dash;
      sourceInfo.m3u8Url = source.hls;
      sourceInfo.progUrl = progSourceInfo.progUrl;
      sourceInfo.progBitrate = progSourceInfo.progBitrate;
    }

    const subtitle = this.player.getSubtitle() || {};

    const getDroppedFrames = () => {
      if (this.player.isReady()) {
        return this.player.getDroppedFrames();
      }
      return 0;
    };

    const info: PlaybackInfo = {
      ...super.getCommonPlaybackInfo(),
      size: this.player.isFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isLive: this.player.isLive(),
      videoDuration: this.player.getDuration(),
      streamFormat: this.player.getStreamType(),
      videoWindowWidth: this.player.getFigure().offsetWidth,
      videoWindowHeight: this.player.getFigure().offsetHeight,
      isMuted: this.player.isMuted(),
      isCasting: this.player.isCasting(),
      audioLanguage: this.player.getAudio() != null ? this.player.getAudio().lang : null,
      subtitleEnabled: subtitle.id != null,
      subtitleLanguage: subtitle.lang,
      droppedFrames: getDroppedFrames(),
      ...sourceInfo,
    };

    const videoQuality = this.player.getPlaybackVideoData();

    if (videoQuality) {
      info.videoBitrate = videoQuality.bitrate;
      info.videoPlaybackHeight = videoQuality.height;
      info.videoPlaybackWidth = videoQuality.width;
    }
    if (info.streamFormat === 'progressive') {
      info.videoBitrate = sourceInfo.progBitrate;
    }

    const audioQuality = this.player.getPlaybackAudioData();
    if (audioQuality) {
      info.audioBitrate = audioQuality.bitrate;
    }

    return info;
  }

  public register() {
    const getProgConfigFromProgressiveConfig = (progressive: undefined | string | any[] | any) => {
      if (!progressive) {
        return {
          progUrl: undefined,
          progBitrate: undefined,
        };
      }

      if (typeof progressive === 'string') {
        return {
          progUrl: progressive,
          progBitrate: 0,
        };
      }

      if (Array.isArray(progressive)) {
        const playbackVideoData = this.player.getPlaybackVideoData();
        const progressiveArrayIndex = parseInt(playbackVideoData.id) || 0;
        return {
          progUrl: progressive[progressiveArrayIndex].url,
          progBitrate: progressive[progressiveArrayIndex].bitrate || 0,
        };
      }

      if (typeof progressive === 'object') {
        return {
          progUrl: progressive.url,
          progBitrate: progressive.bitrate || 0,
        };
      }
    };

    this.player.addEventHandler(this.player.EVENT.ON_SOURCE_UNLOADED, (event: any) => {
      this.eventCallback(Event.SOURCE_UNLOADED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_SOURCE_LOADED, (event: any) => {
      this.eventCallback(Event.SOURCE_LOADED, {});
    });

    this.player.addEventHandler(this.player.EVENT.ON_READY, () => {
      this.eventCallback(Event.READY, {});
    });

    this.player.addEventHandler(this.player.EVENT.ON_CAST_STARTED, (event: any) => {
      this.eventCallback(Event.START_CAST, event);
    });

    this.player.addEventHandler(this.player.EVENT.ON_CAST_STOPPED, () => {
      this.eventCallback(Event.END_CAST, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_PLAY, () => {
      this.eventCallback(Event.PLAY, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_PAUSED, () => {
      this.eventCallback(Event.PAUSE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_TIME_CHANGED, () => {
      this.eventCallback(Event.TIMECHANGED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_SEEK, () => {
      this.eventCallback(Event.SEEK, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_SEEKED, () => {
      this.eventCallback(Event.SEEKED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_STALL_STARTED, () => {
      this.eventCallback(Event.START_BUFFERING, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_STALL_ENDED, () => {
      this.eventCallback(Event.END_BUFFERING, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_AUDIO_PLAYBACK_QUALITY_CHANGED, () => {
      const quality = this.player.getPlaybackAudioData();

      if (this.shouldAllowAudioQualityChange(quality.bitrate)) {
        this.setPreviousAudioBitrate(quality.bitrate);
        this.eventCallback(Event.AUDIO_CHANGE, {
          bitrate: quality.bitrate,
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.addEventHandler(this.player.EVENT.ON_VIDEO_PLAYBACK_QUALITY_CHANGED, () => {
      const quality = this.player.getPlaybackVideoData();
      if (this.shouldAllowVideoQualityChange(quality.bitrate)) {
        this.setPreviousVideoBitrate(quality.bitrate);
        this.eventCallback(Event.VIDEO_CHANGE, {
          width: quality.width,
          height: quality.height,
          bitrate: quality.bitrate,
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.addEventHandler(this.player.EVENT.ON_FULLSCREEN_ENTER, () => {
      this.eventCallback(Event.START_FULLSCREEN, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_FULLSCREEN_EXIT, () => {
      this.eventCallback(Event.END_FULLSCREEN, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_AD_STARTED, () => {
      this.eventCallback(Event.START_AD, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_AD_FINISHED, () => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_MUTED, () => {
      this.eventCallback(Event.MUTE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_UNMUTED, () => {
      this.eventCallback(Event.UN_MUTE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_ERROR, (event: any) => {
      this.eventCallback(Event.ERROR, {
        code: event.code,
        message: event.message,
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_PLAYBACK_FINISHED, () => {
      this.eventCallback(Event.PLAYBACK_FINISHED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_DOWNLOAD_FINISHED, (event: any) => {
      if (event.downloadType.indexOf('drm/license/') === 0) {
        this.drmPerformanceInfo = {
          drmType: event.downloadType.replace('drm/license/', ''),
          drmLoadTime: event.downloadTime * 1000,
        };
      }
    });

    this.player.addEventHandler(this.player.EVENT.ON_AUDIO_CHANGED, (event: any) => {
      this.eventCallback(Event.AUDIOTRACK_CHANGED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.addEventHandler(this.player.EVENT.ON_SUBTITLE_CHANGED, (event: any) => {
      this.eventCallback(Event.SUBTITLE_CHANGE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    const handlePageClose = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        let currentTime: number | undefined;
        if (this.player != null) {
          currentTime = this.player.getCurrentTime();
        }
        this.eventCallback(Event.UNLOAD, {
          currentTime,
        });
      }
    };

    window.addEventListener('beforeunload', handlePageClose);
    window.addEventListener('unload', handlePageClose);
  }

  public sourceChange(config: any, timestamp: number) {
    this.stateMachine.sourceChange(config, timestamp, this.player.getCurrentTime());
  }
}
