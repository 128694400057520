const videoFormatCodecMap: {[format: string]: string[]} = {
  avc: ['video/mp4; codecs="avc1.42000a"'],
  vp9: ['video/mp4; codecs="vp09.00.50.08"'],
  av1: ['video/mp4; codecs="av01.0.08M.08"'],
  hevc: ['video/mp4; codecs="hev1.1.6.L93.B0"'],
};

export class CodecHelper {
  public static get supportedVideoFormats(): string[] {
    const codecs = Object.getOwnPropertyNames(videoFormatCodecMap).filter((format) =>
      videoFormatCodecMap[format].some((codec) => this.isCodecSupported(codec))
    );
    return codecs;
  }

  public static isCodecSupported(codec: string): boolean {
    return MediaSource.isTypeSupported(codec);
  }
}
