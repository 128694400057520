import {Bitmovin7Adapter} from '../adapters/Bitmovin7Adapter';
import {Bitmovin8Adapter} from '../adapters/Bitmovin8Adapter';
import {CAFv3Adapter} from '../adapters/CAFv3Adapter';
import {DashjsAdapter} from '../adapters/DashjsAdapter';
import {HlsAdapter} from '../adapters/HlsAdapter';
import {HTMLVideoElementAdapter} from '../adapters/HTMLVideoElementAdapter';
import {ShakaAdapter} from '../adapters/ShakaAdapter';
import {VideojsAdapter} from '../adapters/VideoJsAdapter';
import {CdnProvider} from '../enums/CDNProvider';
import {Player} from '../enums/Player';
import {VERSION} from '../Version';

// tslint:disable-next-line:variable-name
const AnalyticsModule = {
  name: 'analytics',
  module: {
    Analytics: Bitmovin8Adapter,
  },
  hooks: {
    setup: (module: any, player: any) => {
      const config = player.getConfig();
      if (!config || config.analytics === false) {
        return Promise.resolve();
      }
      const analyticsModule = module.Analytics;
      const analyticsModuleInstance = new analyticsModule(player);
      return Promise.resolve(analyticsModuleInstance);
    },
  },
};

export {Player as Players};
export {CdnProvider as CdnProviders};
export {AnalyticsModule as PlayerModule};
export {VERSION as version};
export {HlsAdapter};
export {Bitmovin8Adapter};
export {Bitmovin7Adapter};
export {VideojsAdapter};
export {CAFv3Adapter};
export {ShakaAdapter};
export {DashjsAdapter};
export {HTMLVideoElementAdapter};
export {adapters};

const adapters = {
  HlsAdapter,
  Bitmovin8Adapter,
  Bitmovin7Adapter,
  VideojsAdapter,
  ShakaAdapter,
  DashjsAdapter,
  CAFv3Adapter,
  HTMLVideoElementAdapter,
};

export default {
  PlayerModule: AnalyticsModule,
};
