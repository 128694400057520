import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {logger} from '../utils/Logger';
import {Adapter} from './Adapter';
import {Bitmovin7InternalAdapter} from './internal/Bitmovin7InternalAdapter';

export class Bitmovin7Adapter extends Adapter implements AdapterAPI {
  constructor(player, opts?: AnalyticsStateMachineOptions) {
    super();
    this.augment(player, opts);
  }

  private augment(player, opts?: AnalyticsStateMachineOptions) {
    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);

    const that = this;
    // decorate player to intercept setup
    const originalSetup = player.setup;

    // tslint:disable-next-line
    player.setup = function () {
      const playerSetupPromise = originalSetup.apply(player, arguments);

      if (arguments.length === 0) {
        return playerSetupPromise;
      }
      const config = arguments[0];
      const analyticsConfig = config.analytics;
      if (!analyticsConfig) {
        logger.error('Bitmovin Analytics: No analytics config provided!');
        return playerSetupPromise;
      }

      that.internalAdapter = new Bitmovin7InternalAdapter(player, opts);
      that.analytics = new Analytics(analyticsConfig, that.internalAdapter);
      (player as any).analytics = that.analytics;
      that.wrapPlayerLoad(player, that.analytics);

      return playerSetupPromise;
    };
  }

  private wrapPlayerLoad(player, analytics: Analytics) {
    const originalLoad = player.load;
    // tslint:disable-next-line
    player.load = function () {
      if (arguments.length > 0) {
        const analyticsConfig = arguments[0].analytics;
        // we reset the analytics and reload with a new config
        analytics.sourceChange(analyticsConfig);
      }

      return originalLoad.apply(player, arguments);
    };
  }

  // Checks if analytics is already initialized on this player instance
  private hasPlayerAlreadyBeenAugmented(player): boolean {
    return (player as any).__analyticsHasBeenSetup;
  }
  // Marks the player instance as already instrumented by analytics
  private markPlayerInstanceAsAugmented(player) {
    (player as any).__analyticsHasBeenSetup = true;
  }
}
