import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {Adapter} from './Adapter';
import {CAFv3InternalAdapter} from './internal/CAFv3InternalAdapter';

export class CAFv3Adapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    this.internalAdapter = new CAFv3InternalAdapter(player, opts);
    this.analytics = new Analytics(config, this.internalAdapter);
  }
}
