import {Ad, LinearAd, VastAdData} from 'bitmovin-player';
import * as Utils from '../utils/Utils';

export class AdSample {
  private static parseSkipAfter(skipAfter?: number) {
    if (skipAfter === undefined) {
      return undefined;
    }
    if (skipAfter === -1) {
      return skipAfter;
    }
    return Utils.calculateTime(skipAfter);
  }

  public wrapperAdsCount?: number;
  public adSkippable?: boolean;
  public adSkippableAfter?: number;
  public adClickthroughUrl?: string;
  public adDescription?: string;
  public adDuration?: number;
  public adId?: string;
  public adImpressionId?: string;
  public adPlaybackHeight?: number;
  public adPlaybackWidth?: number;
  public adStartupTime?: number;
  public adSystem?: string;
  public adTitle?: string;
  public advertiserName?: string;
  public apiFramework?: string;
  public audioBitrate?: number;
  public clicked: number = 0;
  public clickPosition?: number;
  public closed: number = 0;
  public closePosition?: number;
  public completed: number = 0;
  public creativeAdId?: string;
  public creativeId?: string;
  public dealId?: string;
  public isLinear?: boolean;
  public mediaPath?: string;
  public mediaServer?: string;
  public mediaUrl?: string;
  public midpoint: number = 0;
  public minSuggestedDuration?: number;
  public percentageInViewport?: number;
  public quartile1: number = 0;
  public quartile3: number = 0;
  public skipped: number = 0;
  public skipPosition?: number;
  public started: number = 0;
  public streamFormat?: string;
  public surveyUrl?: string;
  public time?: number;
  public timeHovered?: number;
  public timeInViewport?: number;
  public timePlayed?: number;
  public timeUntilHover?: number;
  public universalAdIdRegistry?: string;
  public universalAdIdValue?: string;
  public videoBitrate?: number;
  public adPodPosition?: number;
  public exitPosition?: number;
  public playPercentage?: number;
  public skipPercentage?: number;
  public clickPercentage?: number;
  public closePercentage?: number;
  public errorPosition?: number;
  public errorPercentage?: number;
  public timeToContent?: number;
  public timeFromContent?: number;

  constructor(ad?: Ad) {
    if (ad) {
      this.adClickthroughUrl = ad.clickThroughUrl;
      this.adId = ad.id;
      this.mediaUrl = ad.mediaFileUrl;
      const mediaUrlDetails = Utils.getHostnameAndPathFromUrl(this.mediaUrl || '');
      this.mediaPath = mediaUrlDetails.path;
      this.mediaServer = mediaUrlDetails.hostname;
      this.isLinear = ad.isLinear;
      this.adPlaybackHeight = ad.height;
      this.adPlaybackWidth = ad.width;

      if (ad.data) {
        this.videoBitrate = ad.data.bitrate === undefined ? undefined : ad.data.bitrate * 1000;
        this.streamFormat = ad.data.mimeType;
        // data.minBitrate
        // data.maxBitrate
      }

      const data = ad.data as VastAdData;
      if (data) {
        if (data.adSystem) {
          this.adSystem = data.adSystem.name;
          // this.adSystemVersion = data.adSystem.version;
        }
        if (data.advertiser) {
          this.advertiserName = data.advertiser.name;
          // this.advertiserId = data.advertiser.id;
        }
        // data.codec
        // data.delivery
        // data.pricing
        this.apiFramework = data.apiFramework;

        if (data.creative) {
          this.creativeAdId = data.creative.adId;
          this.creativeId = data.creative.id;

          if (data.creative.universalAdId) {
            this.universalAdIdRegistry = data.creative.universalAdId.idRegistry;
            this.universalAdIdValue = data.creative.universalAdId.value;
          }
        }
        this.adDescription = data.adDescription;
        this.minSuggestedDuration = Utils.calculateTimeWithUndefined(data.minSuggestedDuration);
        if (data.survey) {
          this.surveyUrl = data.survey.uri;
          // data.survey.type;
        }
        this.adTitle = data.adTitle;
        this.wrapperAdsCount = data.wrapperAdIds ? data.wrapperAdIds.length : 0;
      }
    }
    const linearAd = ad as LinearAd;
    if (linearAd) {
      this.adSkippableAfter = AdSample.parseSkipAfter(linearAd.skippableAfter);
      this.adSkippable =
        linearAd.skippable || (this.adSkippableAfter === undefined ? undefined : this.adSkippableAfter >= 0);
      this.adDuration = Utils.calculateTime(linearAd.duration);
    }
  }
}
