import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {Backend} from './Backend';

export class QueueBackend implements Backend {
  public queue: Sample[] = [];
  public unloadQueue: Sample[] = [];
  public syncQueue: Sample[] = [];
  public adQueue: Array<AdSample & AdBreakSample & AdAnalyticsSample> = [];

  public sendRequest(sample: Sample) {
    this.queue.push(sample);
  }
  public sendUnloadRequest(sample: Sample) {
    this.unloadQueue.push(sample);
  }
  public sendRequestSynchronous(sample: Sample) {
    this.syncQueue.push(sample);
  }
  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.adQueue.push(sample);
  }

  public flushTo(backend: Backend) {
    this.queue.forEach((e) => {
      backend.sendRequest(e);
    });
    this.unloadQueue.forEach((e) => {
      backend.sendUnloadRequest(e);
    });
    this.syncQueue.forEach((e) => {
      backend.sendRequestSynchronous(e);
    });
    this.adQueue.forEach((e) => {
      backend.sendAdRequest(e);
    });
  }
}
