import {Sample} from './Sample';

export class AdAnalyticsSample {
  public adModule?: string;
  public adModuleVersion?: string;
  public videoImpressionId?: string;
  public userAgent?: string;
  public language?: string;
  public cdnProvider?: string;
  public customData1?: string;
  public customData2?: string;
  public customData3?: string;
  public customData4?: string;
  public customData5?: string;
  public customData6?: string;
  public customData7?: string;
  public customUserId?: string;
  public domain?: string;
  public experimentName?: string;
  public key?: string;
  public path?: string;
  public player?: string;
  public playerKey?: string;
  public playerTech?: string;
  public screenHeight?: number;
  public screenWidth?: number;
  public version?: string;
  public size?: string;
  public userId?: string;
  public videoId?: string;
  public videoTitle?: string;
  public videoWindowHeight?: number;
  public videoWindowWidth?: number;
  public playerStartupTime?: number;
  public analyticsVersion?: string;
  public pageLoadTime?: number;
  public pageLoadType?: number;
  public autoplay?: boolean;
  public platform: string = 'web';
  public audioCodec?: string;
  public videoCodec?: string;

  constructor(sample?: Sample) {
    if (!sample) {
      return;
    }
    this.videoImpressionId = sample.impressionId;
    this.userAgent = sample.userAgent;
    this.language = sample.language;
    this.cdnProvider = sample.cdnProvider;
    this.customData1 = sample.customData1;
    this.customData2 = sample.customData2;
    this.customData3 = sample.customData3;
    this.customData4 = sample.customData4;
    this.customData5 = sample.customData5;
    this.customData6 = sample.customData6;
    this.customData7 = sample.customData7;
    this.customUserId = sample.customUserId;
    this.domain = sample.domain;
    this.experimentName = sample.experimentName;
    this.key = sample.key;
    this.path = sample.path;
    this.player = sample.player;
    this.playerKey = sample.playerKey;
    this.playerTech = sample.playerTech;
    this.screenHeight = sample.screenHeight;
    this.screenWidth = sample.screenWidth;
    this.version = sample.version;
    this.size = sample.size;
    this.userId = sample.userId;
    this.videoId = sample.videoId;
    this.videoTitle = sample.videoTitle;
    this.videoWindowHeight = sample.videoWindowHeight;
    this.videoWindowWidth = sample.videoWindowWidth;
    this.audioCodec = sample.audioCodec;
    this.videoCodec = sample.videoCodec;
  }
}
