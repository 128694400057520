import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {logger} from '../utils/Logger';
import {Adapter} from './Adapter';
import {VideojsInternalAdapter} from './internal/VideojsInternalAdapter';

export class VideojsAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    const attachedAdapter = (player as any).bitmovinAnalytics as VideojsAdapter;
    if (attachedAdapter != null) {
      logger.warning('There is already an adapter attached to this player instance.');
      this.internalAdapter = attachedAdapter.internalAdapter;
      this.analytics = attachedAdapter.analytics;
      this.sourceChange(config);
    } else {
      this.internalAdapter = new VideojsInternalAdapter(player as videojs.default.Player, opts);
      this.analytics = new Analytics(config, this.internalAdapter);
    }
    (player as any).bitmovinAnalytics = this;
  }
}
