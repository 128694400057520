import {PAGE_LOAD_TYPE} from '../enums/PageLoadType';

/* tslint:disable */
export const validString = (text: any): boolean => {
  return text != null && typeof text === 'string';
};

export const isNullOrEmptyString = (text: any): boolean => {
  return text == null || typeof text != 'string' || text.length === 0;
};

export const validBoolean = (bool: any): boolean => {
  return bool != null && typeof bool === 'boolean';
};

export const validNumber = (num: any): boolean => {
  return num != null && typeof num === 'number';
};

export const sanitizePath = (path: string): string => {
  return path.replace(/\/$/g, '');
};
/* tslint:enable */

export const calculateTimeWithUndefined = (time?: number): number | undefined => {
  if (time === undefined) {
    return time;
  }
  time = time * 1000;
  return Math.round(time);
};

export const calculateTime = (time: number): number => {
  time = time * 1000;
  return Math.round(time);
};

export const getCurrentTimestamp = (): number => {
  return Date.now();
};

export const getDurationFromTimestampToNow = (timestamp: number): number => {
  return getCurrentTimestamp() - timestamp;
};

export const generateUUID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line
    const r = (Math.random() * 16) | 0;
    // tslint:disable-next-line
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getCookie = (cname: string): string => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// tslint:disable-next-line
export const noOp = () => {};

export const getCustomDataString = (customData: any): string | undefined => {
  if (typeof customData === 'object') {
    return JSON.stringify(customData);
  } else if (typeof customData === 'function') {
    return getCustomDataString(customData());
  } else if (typeof customData === 'undefined') {
    return customData;
  } else if (typeof customData !== 'string') {
    return String(customData);
  }

  return customData;
};

export const getDocumentPropWithPrefix = (prop: string): string | undefined => {
  const prefixes = ['webkit', 'moz', 'ms', 'o'];
  if (prop in document) {
    return prop;
  }

  const pascalCase = prop.charAt(0).toUpperCase() + prop.slice(1);
  for (const prefix of prefixes) {
    if (prefix + pascalCase in document) {
      return prefix + pascalCase;
    }
  }
  return undefined;
};

export const isVideoInFullscreen = () => {
  const fullscreenProp = getDocumentPropWithPrefix('fullscreenElement');
  return fullscreenProp !== undefined && document[fullscreenProp] && document[fullscreenProp].nodeName === 'VIDEO';
};

export const getHiddenProp = (): string | undefined => {
  return getDocumentPropWithPrefix('hidden');
};

export function getPageLoadType(): PAGE_LOAD_TYPE {
  // @ts-ignore
  if (document[getHiddenProp()] === true) {
    return PAGE_LOAD_TYPE.BACKGROUND;
  }
  return PAGE_LOAD_TYPE.FOREGROUND;
}

export const getHostnameAndPathFromUrl = (url: string) => {
  const domElement = document.createElement('a');
  domElement.href = url;

  return {hostname: domElement.hostname, path: domElement.pathname};
};

export const calculatePercentage = (numerator?: number, denominator?: number): number | undefined => {
  if (denominator === undefined || denominator === 0) {
    return undefined;
  }
  return Math.round(((numerator || 0) / denominator) * 100);
};

/* tslint:disable */
export const getBrowserInfo = () => {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return {name: 'IE', version: tem[1] || ''};
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]};
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return {name: M[0], version: M[1]};
};
/* tslint:enable */

export const getURLResourceName = (url: string): string => {
  if (url.length === 0) {
    return '';
  }
  return url.split('/').pop() || '';
};

export const joinUrls = (...args: string[]): string => {
  return args.reduce((prev, current) => {
    const prevUrlValue = endsWith(prev, '/') && current !== undefined ? prev.substr(0, prev.length - 1) : prev;
    const nextUrlValue = startsWith(current, '/') ? current.substr(1) : current;
    return prevUrlValue + '/' + nextUrlValue;
  });
};

export const endsWith = (str: string, suffix: string): boolean => {
  if (String.prototype.endsWith) {
    return str.endsWith(suffix);
  }

  return str.substring(str.length - suffix.length) === suffix;
};

export const startsWith = (str: string, prefix: string): boolean => {
  if (String.prototype.startsWith) {
    return str.startsWith(prefix);
  }
  return str.substring(0, prefix.length) === prefix;
};
