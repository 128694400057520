import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {logger} from '../utils/Logger';
import {InternalAdapterAPI} from './internal/InternalAdapterAPI';

export abstract class Adapter {
  protected analytics!: Analytics;
  protected internalAdapter!: InternalAdapterAPI;

  public getCurrentImpressionId(): string | undefined {
    if (!this.guardAgainstNotInitializedAnalytics()) {
      return;
    }
    return this.analytics.getCurrentImpressionId();
  }

  public setCustomData(values) {
    if (!this.guardAgainstNotInitializedAnalytics()) {
      return;
    }
    this.analytics.setCustomData(values);
  }

  public setCustomDataOnce(values) {
    if (!this.guardAgainstNotInitializedAnalytics()) {
      return;
    }
    this.analytics.setCustomDataOnce(values);
  }

  public sourceChange(config: AnalyticsConfig) {
    if (!this.guardAgainstNotInitializedAnalytics()) {
      return;
    }
    this.analytics.sourceChange(config);
  }

  private guardAgainstNotInitializedAnalytics(): boolean {
    if (!this.analytics) {
      logger.log("Bitmovin Analytics: Adapter hasn't been initialized yet");
      return false;
    }
    return true;
  }
}
