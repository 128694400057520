import {AnalyticsConfig} from '../types/AnalyticsConfig';
import * as Utils from '../utils/Utils';

export const BITMOVIN_ANALYTICS_UUID_COOKIE_NAME = 'bitmovin_analytics_uuid';

export class SessionPersistenceHandler {
  public readonly userId: string;

  private readonly cookiesEnabled: boolean;
  private readonly cookiesDomain: string | null;

  constructor(config: AnalyticsConfig) {
    const collectorConfig = config.config;
    this.cookiesEnabled = collectorConfig == null || collectorConfig.cookiesEnabled !== false;
    if (collectorConfig != null && collectorConfig.cookiesDomain != null) {
      this.cookiesDomain = collectorConfig.cookiesDomain;
    } else {
      this.cookiesDomain = null;
    }

    if (!this.cookiesEnabled) {
      this.ensureNoCookiePresent();
    }

    this.userId = this.setupUserId();
  }

  private setupUserId() {
    let userId = Utils.getCookie(BITMOVIN_ANALYTICS_UUID_COOKIE_NAME);
    if (Utils.isNullOrEmptyString(userId)) {
      userId = this.createAndSetNewUserId();
    }
    return userId;
  }

  private createAndSetNewUserId() {
    const generatedId = Utils.generateUUID();
    this.writeCookie(generatedId);
    return generatedId;
  }

  private ensureNoCookiePresent() {
    const userId = Utils.getCookie(BITMOVIN_ANALYTICS_UUID_COOKIE_NAME);
    if (Utils.isNullOrEmptyString(userId)) {
      return;
    }
    const cookie = `${BITMOVIN_ANALYTICS_UUID_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = cookie;
  }

  private writeCookie(userId: string) {
    if (!this.cookiesEnabled) {
      return;
    }
    let cookie = `${BITMOVIN_ANALYTICS_UUID_COOKIE_NAME}=${userId}; path=/; expires="Mon, 31 Dec 2029 23:00:00 GMT"; max-age=315400000`;

    if (this.cookiesDomain != null) {
      cookie += `; domain=${this.cookiesDomain}`;
    }

    document.cookie = cookie;
  }
}
