import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {HTML5InternalAdapter} from './HTML5InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class ShakaInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(private player: any, opts?: AnalyticsStateMachineOptions) {
    super(player.getMediaElement(), opts);
  }

  /**
   * @override
   */
  public initialize() {
    super.initialize();
    let videoElement: HTMLVideoElement | undefined;
    try {
      videoElement = this.player.getMediaElement();
    } catch (e) {} // tslint:disable-line:no-empty

    if (videoElement == null) {
      const onStateChangeEventListener = (state) => {
        if (state.state === 'media-source') {
          this.player.removeEventListener('onstatechange', onStateChangeEventListener);
          videoElement = this.player.getMediaElement();
          this.setMediaElement(videoElement);
        }
      };
      this.player.addEventListener('onstatechange', onStateChangeEventListener);
    } else {
      this.setMediaElement(videoElement);
    }
  }

  public getPlayerName = () => Player.SHAKA;

  public getPlayerVersion = () => this.player.constructor.version;

  public isLive = () => {
    return this.player ? this.player.isLive() : false;
  };

  /**
   * @override
   */
  public getMIMEType() {
    // FIXME: Could be HLS too, Shaka probably has a method to find out
    return MIMETypes.DASH;
  }

  /**
   * @override
   */
  public getStreamURL() {
    return this.player ? this.player.getAssetUri() : null;
  }

  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    const variantTracks = this.player.getVariantTracks();

    const activeVideoTrack = variantTracks
      .filter((track: any) => track.active)
      .filter((track: any) => track.videoCodec || track.videoId !== undefined)[0];

    if (!activeVideoTrack) {
      // can only happen for audio-only streams
      return null;
    }

    const bitrate = activeVideoTrack.videoBandwidth || activeVideoTrack.bandwidth;
    const width = activeVideoTrack.width;
    const height = activeVideoTrack.height;

    return {
      bitrate,
      width,
      height,
    };
  }
}
