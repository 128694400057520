import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {Backend} from './Backend';

export class SequenceNumberBackend implements Backend {
  private underlying: Backend;
  private sequenceNumber: number = 0;

  constructor(underlying: Backend) {
    this.underlying = underlying;
  }

  public sendRequest(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    this.underlying.sendRequest(sample);
  }

  public sendUnloadRequest(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    this.underlying.sendUnloadRequest(sample);
  }

  public sendRequestSynchronous(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    this.underlying.sendRequestSynchronous(sample);
  }

  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.underlying.sendAdRequest(sample);
  }

  private updateSampleBeforeSending(sample: Sample) {
    sample.sequenceNumber = this.sequenceNumber++;
  }
}
