import {PlayerAPI, SeekEvent, UserInteractionEvent} from 'bitmovin-player';
import {Bitmovin8AnalyticsStateMachine} from '../../analyticsStateMachines/Bitmovin8AnalyticsStateMachine';
import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';
import {SegmentTracker} from '../../core/SegmentTracker';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {ErrorCode} from '../../enums/ErrorCode';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {SegmentInfo} from '../../types/SegmentInfo';
import {getSourceInfoFromBitmovinSourceConfig} from '../../utils/BitmovinProgressiveSourceHelper';
import * as AnalyticsSettings from '../../utils/Settings';
import {AdModuleAPI} from './ads/AdModuleAPI';
import {Bitmovin8AdModule} from './ads/Bitmovin8AdModule';
import {Bitmovin8SegmentTrackerAdapter} from './Bitmovin8SegmentTrackerAdapter';
import {Bitmovin8SpeedMeterAdapter} from './Bitmovin8SpeedMeterAdapter';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

enum ViewMode {
  Inline = 'inline',
  Fullscreen = 'fullscreen',
  PictureInPicture = 'pictureinpicture',
}

export class Bitmovin8InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  get downloadSpeedInfo(): DownloadSpeedInfo {
    return this.speedMeter.getInfo();
  }

  get segments(): SegmentInfo[] {
    return this.segmentTracker.getSegments();
  }

  get adModule(): AdModuleAPI | undefined {
    return this._adModule;
  }

  get supportsDeferredLicenseLoading(): boolean {
    return true;
  }
  public readonly videoCompletionTracker: VideoCompletionTracker;

  private onBeforeUnLoadEvent: boolean = false;
  private speedMeter: DownloadSpeedMeter;
  private segmentTracker: SegmentTracker;
  private _adModule?: AdModuleAPI;

  constructor(private player: PlayerAPI, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new Bitmovin8AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.speedMeter = new Bitmovin8SpeedMeterAdapter(player, new DownloadSpeedMeter()).getDownloadSpeedMeter();
    this.segmentTracker = new Bitmovin8SegmentTrackerAdapter(player, new SegmentTracker()).getSegmentTracker();
    this.videoCompletionTracker = new VideoCompletionTracker();
  }

  public initialize() {
    this.register();
  }

  public clearValues(): void {
    this.speedMeter.reset();
  }

  public clearSegments(): void {
    this.segmentTracker.reset();
  }

  public getPlayerVersion = () => this.player.version;
  public getPlayerName = () => Player.BITMOVIN;
  public getPlayerTech = () => this.player.getPlayerType();
  public getAutoPlay(): boolean {
    if (this.player.getConfig().playback) {
      return (this.player.getConfig() as any).playback.autoplay || false;
    }
    return false;
  }
  public getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  public getCurrentPlaybackInfo(): PlaybackInfo {
    const sourceInfo: any = {};
    const source = this.player.getSource();
    if (source) {
      const progSourceInfo = getSourceInfoFromBitmovinSourceConfig(source.progressive, this.player);
      sourceInfo.videoTitle = source.title;
      sourceInfo.mpdUrl = source.dash;
      sourceInfo.m3u8Url = source.hls;
      sourceInfo.progUrl = progSourceInfo.progUrl;
      sourceInfo.progBitrate = progSourceInfo.progBitrate;
    }

    let enabledSubtitle;
    if (this.player.subtitles) {
      enabledSubtitle = this.player.subtitles.list().find((s) => s.enabled === true);
    }

    const getDroppedFrames = () => {
      if (this.player.getSource() != null) {
        return this.player.getDroppedVideoFrames();
      }
      return 0;
    };

    const info: PlaybackInfo = {
      ...super.getCommonPlaybackInfo(),
      size: this.player.getViewMode() === ViewMode.Fullscreen ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isLive: this.player.isLive(),
      videoDuration: this.player.getDuration(),
      streamFormat: this.player.getStreamType(),
      videoWindowWidth: this.player.getContainer().offsetWidth,
      videoWindowHeight: this.player.getContainer().offsetHeight,
      isMuted: this.player.isMuted(),
      isCasting: this.player.isCasting(),
      audioLanguage: this.player.getAudio() != null ? this.player.getAudio().lang : null,
      subtitleEnabled: enabledSubtitle != null,
      subtitleLanguage: enabledSubtitle != null ? enabledSubtitle.lang : null,
      droppedFrames: getDroppedFrames(),
      ...sourceInfo,
    };

    const videoQuality = this.player.getPlaybackVideoData();
    info.videoBitrate = videoQuality.bitrate;
    info.videoPlaybackHeight = videoQuality.height;
    info.videoPlaybackWidth = videoQuality.width;
    info.videoCodec = (videoQuality as any).codec;

    if (info.streamFormat === 'progressive') {
      info.videoBitrate = sourceInfo.progBitrate;
    }

    const audioQuality = this.player.getPlaybackAudioData();
    info.audioBitrate = audioQuality.bitrate;
    info.audioCodec = (audioQuality as any).codec;

    return info;
  }

  public register() {
    this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, (event: any) => {
      this.segmentTracker.reset();
      this.eventCallback(Event.SOURCE_UNLOADED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SourceLoaded, (event: any) => {
      this.videoCompletionTracker.reset();
      this.videoCompletionTracker.setVideoDuration(this.player.getDuration());
      this.eventCallback(Event.SOURCE_LOADED, {});
    });

    this.player.on(this.player.exports.PlayerEvent.CastStarted, (event: any) => {
      this.eventCallback(Event.START_CAST, event);
    });

    this.player.on(this.player.exports.PlayerEvent.CastStopped, () => {
      this.eventCallback(Event.END_CAST, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Play, (e: UserInteractionEvent) => {
      if (e.issuer !== 'ui-seek') {
        this.eventCallback(Event.PLAY, {
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Playing, (e: UserInteractionEvent) => {
      if (e.issuer !== 'advertising-api') {
        this.eventCallback(Event.PLAYING, {
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Paused, (e: UserInteractionEvent) => {
      if (e.issuer !== 'ui-seek') {
        this.eventCallback(Event.PAUSE, {
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.TimeChanged, () => {
      this.eventCallback(Event.TIMECHANGED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Seek, (e: UserInteractionEvent) => {
      if (this.allowSeeking(e)) {
        this.eventCallback(Event.SEEK, {
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.Seeked, () => {
      this.eventCallback(Event.SEEKED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.StallStarted, () => {
      this.eventCallback(Event.START_BUFFERING, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.StallEnded, () => {
      this.eventCallback(Event.END_BUFFERING, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AudioPlaybackQualityChanged, () => {
      const quality = this.player.getPlaybackAudioData();

      if (this.shouldAllowAudioQualityChange(quality.bitrate)) {
        this.setPreviousAudioBitrate(quality.bitrate);
        this.eventCallback(Event.AUDIO_CHANGE, {
          bitrate: quality.bitrate,
          currentTime: this.player.getCurrentTime(),
          codec: (quality as any).codec,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.VideoPlaybackQualityChanged, () => {
      const quality = this.player.getPlaybackVideoData();

      if (this.shouldAllowVideoQualityChange(quality.bitrate)) {
        this.setPreviousVideoBitrate(quality.bitrate);
        this.eventCallback(Event.VIDEO_CHANGE, {
          width: quality.width,
          height: quality.height,
          bitrate: quality.bitrate,
          currentTime: this.player.getCurrentTime(),
          codec: (quality as any).codec,
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.ViewModeChanged, (e: any) => {
      if (e.to === 'fullscreen') {
        this.eventCallback(Event.START_FULLSCREEN, {
          currentTime: this.player.getCurrentTime(),
        });
      } else if (e.from === 'fullscreen') {
        this.eventCallback(Event.END_FULLSCREEN, {
          currentTime: this.player.getCurrentTime(),
        });
      }
    });

    this.player.on(this.player.exports.PlayerEvent.AdStarted, (event: any) => {
      this.eventCallback(Event.START_AD, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AdFinished, (event: any) => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Muted, () => {
      this.eventCallback(Event.MUTE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Unmuted, () => {
      this.eventCallback(Event.UN_MUTE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.AdSkipped, (event: any) => {
      this.eventCallback(Event.END_AD, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.Error, (event: any) => {
      this.eventCallback(Event.ERROR, {
        code: event.code,
        message: event.name,
        data: event.data,
        currentTime: this.player.getCurrentTime(),
      });
      if (
        event.code === ErrorCode.BitmovinPlayerLicensingError ||
        event.code === ErrorCode.SetupMissingLicenseWhitelist
      ) {
        this._onLicenseCallFailed.dispatch({});
      }
    });

    this.player.on(this.player.exports.PlayerEvent.PlaybackFinished, () => {
      this.eventCallback(Event.PLAYBACK_FINISHED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.DownloadFinished, (event: any) => {
      if (event.downloadType.indexOf('drm/license/') === 0) {
        this.drmPerformanceInfo = {
          drmType: event.downloadType.replace('drm/license/', ''),
          drmLoadTime: event.downloadTime * 1000,
        };
      }
    });

    this.player.on(this.player.exports.PlayerEvent.AudioChanged, (event: any) => {
      this.eventCallback(Event.AUDIOTRACK_CHANGED, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SubtitleEnabled, (event: any) => {
      this.eventCallback(Event.SUBTITLE_CHANGE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    this.player.on(this.player.exports.PlayerEvent.SubtitleDisabled, (event: any) => {
      this.eventCallback(Event.SUBTITLE_CHANGE, {
        currentTime: this.player.getCurrentTime(),
      });
    });

    // @ts-ignore
    this.player.on(this.player.exports.PlayerEvent.LicenseValidated, (event: any) => {
      if (event.data.analytics && event.data.analytics.key !== undefined) {
        this._onLicenseKeyReceived.dispatch({licenseKey: event.data.analytics.key});
      } else {
        this._onLicenseCallFailed.dispatch({});
      }
    });

    const handlePageClose = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        let currentTime: number | undefined;
        if (this.player != null) {
          currentTime = this.player.getCurrentTime();
        }
        this.eventCallback(Event.UNLOAD, {
          currentTime,
        });
      }
    };

    window.addEventListener('beforeunload', handlePageClose);
    window.addEventListener('unload', handlePageClose);

    this._adModule = new Bitmovin8AdModule(this.player);
  }

  public sourceChange(config, timestamp) {
    this.stateMachine.sourceChange(config, timestamp, this.player.getCurrentTime());
  }

  public onError(): void {
    this.clearSegments();
  }

  // with this check we ignore seek events which are smaller than the Threshold
  // mainly to ignore keyboard triggered seeks
  private allowSeeking(e: UserInteractionEvent): boolean {
    const seekEvent = e as SeekEvent;
    const seekDifference = Math.abs(seekEvent.position - seekEvent.seekTarget);
    return seekDifference > AnalyticsSettings.ANALYTICS_MIN_SEEK_DIFFERENCE_THRESHOLD;
  }
}
