import {SegmentInfo} from '../types/SegmentInfo';

export class SegmentTracker {
  protected segments: SegmentInfo[];

  constructor() {
    this.segments = [];
  }

  public reset(): void {
    this.segments = [];
  }

  public addSegment(segment: SegmentInfo): void {
    this.segments.push(segment);
  }

  public removeSegment(url: string): void {
    this.segments = this.segments.filter((s) => s.url !== url);
  }

  public getSegments(): SegmentInfo[] {
    return this.segments;
  }
}
