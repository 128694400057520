import {AdapterAPI} from '../api/AdapterAPI';
import {Analytics} from '../core/Analytics';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {Adapter} from './Adapter';
import {HTMLVideoElementInternalAdapter} from './internal/HTMLVideoElementInternalAdapter';

export class HTMLVideoElementAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: HTMLVideoElement, opts?: AnalyticsStateMachineOptions) {
    super();
    this.internalAdapter = new HTMLVideoElementInternalAdapter(player, opts);
    this.analytics = new Analytics(config, this.internalAdapter);
  }
}
