import {HTML5AnalyticsStateMachine} from '../../analyticsStateMachines/HTML5AnalyticsStateMachine';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {Event} from '../../enums/Event';
import {getMIMETypeFromFileExtension} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {getStreamTypeFromMIMEType} from '../../enums/StreamTypes';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {SegmentInfo} from '../../types/SegmentInfo';
import {StreamSources} from '../../types/StreamSources';
import {SubtitleInfo} from '../../types/SubtitleInfo';
import {isVideoInFullscreen} from '../../utils/Utils';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export abstract class HTML5InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  get segments(): SegmentInfo[] {
    return [];
  }

  private static BUFFERING_TIMECHANGED_TIMEOUT = 1000;
  public mediaElEventHandlers: Array<{event: string; handler: any}>;
  public abstract getPlayerVersion: () => string;
  public readonly videoCompletionTracker: VideoCompletionTracker;
  protected needsFirstPlayIntent: boolean;
  private onBeforeUnLoadEvent: boolean = false;

  private bufferingTimeout?: number;
  private isBuffering: boolean;
  private lastIsLiveStatus: boolean;
  private isPaused: boolean;
  private isSeeking: boolean;
  private previousMediaTime: number;
  private previousClientTime: number;
  private needsReadyEvent: boolean;
  private mediaElementSet: boolean;

  constructor(protected mediaElement: HTMLVideoElement | null, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new HTML5AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.mediaElEventHandlers = [];
    this.bufferingTimeout = undefined;
    this.isBuffering = false;
    this.lastIsLiveStatus = false;
    this.isPaused = false;
    this.isSeeking = false;
    this.previousMediaTime = 0;
    this.previousClientTime = 0;
    this.needsReadyEvent = true;
    this.needsFirstPlayIntent = true;
    this.mediaElementSet = false;
    this.videoCompletionTracker = new VideoCompletionTracker();
  }
  public getPlayerName = () => Player.HTML5;
  public getPlayerTech = () => 'html5';
  public getAutoPlay = () => (this.mediaElement ? this.mediaElement.autoplay : false);
  public getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  public initialize() {
    if (this.mediaElement) {
      this.setMediaElement();
    }
    this.registerWindowEvents();
  }

  public isLive = () => (this.mediaElement ? this.mediaElement.duration === Infinity : false);

  // this seems very generic. one could put it in a helper
  // and use it in many adapter implementations.
  public getStreamSources(url: string | undefined): StreamSources {
    if (!url) {
      return {};
    }
    const streamType = this.getStreamType();
    switch (streamType) {
      case 'hls':
        return {m3u8Url: url};
      case 'dash':
        return {mpdUrl: url};
      default:
        return {progUrl: url};
    }
  }

  public getCurrentPlaybackInfo(): PlaybackInfo {
    let info: PlaybackInfo = {
      ...this.getCommonPlaybackInfo(),
      ...this.getStreamSources(this.getStreamURL()),
      streamFormat: this.getStreamType(),
      isLive: this.isLive(),
      size: isVideoInFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      droppedFrames: 0, // TODO
      // TODO audioBitrate:
      // TODO isCasting:
      // TODO videoTitle: (currently only from the analytics config)
    };

    if (this.mediaElement) {
      info = {
        ...info,
        videoDuration: this.mediaElement.duration,
        isMuted: this.mediaElement.muted,
        videoWindowHeight: this.mediaElement.height,
        videoWindowWidth: this.mediaElement.width,
      };
    }

    const qualityInfo = this.getCurrentQualityLevelInfo();
    if (qualityInfo) {
      info = {
        ...info,
        videoPlaybackHeight: qualityInfo.height,
        videoPlaybackWidth: qualityInfo.width,
        videoBitrate: qualityInfo.bitrate,
      };
    }

    return info;
  }

  /**
   * Used to setup against the media element.
   * We need this method to desynchronize construction of this class
   * and the actual initialization against the media element.
   * That is because at construction some media engine
   * may not already have the media element attached, for example
   * when passing in the DOM element is happening at once with passing the source URL
   * and can not be decoupled.
   * We are then awaiting an event from the engine and calling this with the media element
   * as argument from our sub-class.
   *
   * This method can also be called without arguments and then it will perform
   * initialization against the existing media element (should only be called once, will throw an error otherwise)
   *
   * It can also be used to replace the element.
   *
   *
   */
  public setMediaElement(mediaElement: HTMLVideoElement | null = null) {
    // replace previously existing, if calld with args
    if (mediaElement && this.mediaElement) {
      this.unregisterMediaElement();
      this.mediaElementSet = false;
    }

    // if called without args we assume it's already there
    // we can also be called with args but without any being there before
    if (mediaElement) {
      this.mediaElement = mediaElement;
    }

    if (!this.mediaElement) {
      throw new Error('No media element owned');
    }

    if (this.mediaElementSet) {
      throw new Error('Media element already set (only call this once)');
    }
    this.mediaElementSet = true;

    this.registerMediaElement();
    this.onMaybeReady();
  }
  public abstract getCurrentQualityLevelInfo(): QualityLevelInfo | null;

  /**
   * Can be overriden by sub-classes
   * @returns {string}
   *
   */
  public getMIMEType(): string | undefined {
    const streamUrl = this.getStreamURL();
    if (!streamUrl || streamUrl === undefined) {
      return;
    }
    return getMIMETypeFromFileExtension(streamUrl);
  }

  /**
   * Can be overriden by sub-classes
   * @returns {string}
   */
  public getStreamType(): string | undefined {
    const mimetype = this.getMIMEType();
    if (mimetype) {
      return getStreamTypeFromMIMEType(mimetype);
    }
  }

  /**
   * Can be overriden by subclasses.
   * @returns {string}
   */
  public getStreamURL(): string | undefined {
    const mediaElement = this.mediaElement;
    if (!mediaElement) {
      return;
    }

    return mediaElement.src;
  }

  public resetMedia() {
    this.mediaElement = null;
    this.mediaElEventHandlers = [];
    window.clearTimeout(this.bufferingTimeout);
  }

  public registerMediaElement() {
    const mediaElement = this.mediaElement;
    if (!mediaElement) {
      return;
    }

    this.listenToMediaElementEvent('loadstart', () => {
      this.eventCallback(Event.READY, {});
    });

    this.listenToMediaElementEvent('loadedmetadata', () => {
      // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
      // HAVE_NOTHING 0 No information is available about the media resource.
      // HAVE_METADATA 1 Enough of the media resource has been retrieved that
      // the metadata attributes are initialized. Seeking will no longer raise an exception.
      // HAVE_CURRENT_DATA 2 Data is available for the current playback position,
      // but not enough to actually play more than one frame.
      // HAVE_FUTURE_DATA  3 Data for the current playback position as well as for
      //  at least a little bit of time into the future is available
      // (in other words, at least two frames of video, for example).
      // HAVE_ENOUGH_DATA  4 Enough data is available—and the download rate is high
      // enough—that the media can be played through to the end without interruption.
      if (mediaElement.readyState !== 1) {
        // we can't really gather any more information at this point
        return;
      }

      // silent
      this.checkQualityLevelAttributes(true);

      // const {duration, autoplay, width, height, videoWidth, videoHeight, muted} = mediaElement;

      // // This is redundant with what we give to updateMetadata method.
      // // Not sure if there are good reasons to keep that so or if we should better centralize.
      // const info = {
      //   type: 'html5',
      //   isLive: this.isLive(),
      //   version: this.getPlayerVersion(),
      //   streamType: this.getStreamType(),
      //   streamUrl: this.getStreamURL(),
      //   duration,
      //   autoplay,
      //   // HTMLVideoElement.width and HTMLVideoElement.height
      //   // is a DOMString that reflects the height HTML attribute,
      //   // which specifies the height of the display area, in CSS pixels.
      //   // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
      //   width: width,
      //   height: height,
      //   // Returns an unsigned long containing the intrinsic
      //   // height of the resource in CSS pixels,
      //   // taking into account the dimensions, aspect ratio,
      //   // clean aperture, resolution, and so forth,
      //   // as defined for the format used by the resource.
      //   // If the element's ready state is HAVE_NOTHING, the value is 0.
      //   // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
      //   videoWindowWidth: videoWidth,
      //   videoWindowHeight: videoHeight,
      //   muted,
      // };

      // silence events if we have not yet intended play
      // if (this.needsFirstPlayIntent_) {
      //   return;
      // }
      if (this.mediaElement != null) {
        this.videoCompletionTracker.reset();
        this.videoCompletionTracker.setVideoDuration(this.mediaElement.duration);
      }
    });

    // We need the PLAY event to indicate the intent to play
    // NOTE: use TIMECHANGED event on 'playing' and trigger PLAY as intended in states.dot graph

    this.listenToMediaElementEvent('play', () => {
      const {currentTime} = mediaElement;

      this.needsFirstPlayIntent = false;

      this.eventCallback(Event.PLAY, {
        currentTime,
      });
    });

    this.listenToMediaElementEvent('pause', () => {
      this.onPaused();
    });

    this.listenToMediaElementEvent('playing', () => {
      const {currentTime} = mediaElement;

      this.isPaused = false;

      // silence events if we have not yet intended play
      if (this.needsFirstPlayIntent) {
        return;
      }

      this.eventCallback(Event.TIMECHANGED, {
        currentTime,
      });
    });

    this.listenToMediaElementEvent('error', () => {
      const {currentTime, error} = mediaElement;

      this.eventCallback(Event.ERROR, {
        currentTime,
        // See https://developer.mozilla.org/en-US/docs/Web/API/MediaError
        code: error ? error.code : null,
        message: error ? error.message : null,
      });
    });

    this.listenToMediaElementEvent('volumechange', () => {
      const {muted, currentTime} = mediaElement;

      if (muted) {
        this.eventCallback(Event.MUTE, {
          currentTime,
        });
      } else {
        this.eventCallback(Event.UN_MUTE, {
          currentTime,
        });
      }
    });

    this.listenToMediaElementEvent('seeking', () => {
      const {currentTime} = mediaElement;

      this.eventCallback(Event.SEEK, {
        currentTime,
      });
    });

    this.listenToMediaElementEvent('seeked', () => {
      const {currentTime} = mediaElement;

      clearTimeout(this.bufferingTimeout);

      this.eventCallback(Event.SEEKED, {
        currentTime,
      });
    });

    this.listenToMediaElementEvent('timeupdate', () => {
      const {currentTime} = mediaElement;

      this.isBuffering = false;
      this.isSeeking = false;

      // silence events if we have not yet intended play
      if (this.needsFirstPlayIntent) {
        return;
      }

      if (!this.isPaused) {
        this.eventCallback(Event.TIMECHANGED, {
          currentTime,
        });
      }

      this.checkQualityLevelAttributes();

      this.checkSeeking();

      // We are doing this in case we can not rely
      // on the "stalled" or "waiting" events in a specific browser
      // and to detect intrinsinc paused states (when we do not get a paused event)
      // but the player is paused already before attach or is paused from initialization on.
      this.checkPlayheadProgress();

      this.previousMediaTime = currentTime;
    });

    // The stalled event is fired when the user agent is trying to fetch media data,
    // but data is unexpectedly not forthcoming.
    // https://developer.mozilla.org/en-US/docs/Web/Events/stalled
    this.listenToMediaElementEvent('stalled', () => {
      // this event doesn't indicate buffering by definition (interupted playback),
      // only that data throughput to playout buffers is not as high as expected
      // It happens on Chrome every once in a while as SourceBuffer's are not fed
      // as fast as the underlying native player may prefer (but it does not lead to
      // interuption).
    });

    // The waiting event is fired when playback has stopped because of a temporary lack of data.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/waiting
    this.listenToMediaElementEvent('waiting', () => {
      // we check here for seeking because a programmatically seek where just
      // the currentTime has been changed does not trigger a proper seek event
      this.checkSeeking();
      this.onBuffering();
    });
  }

  /**
   * Should only be calld when a mediaElement is attached
   */
  public listenToMediaElementEvent(event: any, handler: any) {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    const boundHandler = handler.bind(this);

    this.mediaElEventHandlers.push({event, handler: boundHandler});
    this.mediaElement.addEventListener(event, boundHandler, false);
  }

  public onMaybeReady() {
    if (!this.needsReadyEvent || !this.mediaElement) {
      return;
    }

    this.needsReadyEvent = false;

    const info = this.getCurrentPlaybackInfo();

    this.videoCompletionTracker.reset();
    this.videoCompletionTracker.setVideoDuration(this.mediaElement.duration);
    this.eventCallback(Event.READY, info);
  }

  /**
   * Should only be calld when a mediaElement is attached
   */
  public unregisterMediaElement() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    const mediaElement = this.mediaElement;

    this.mediaElEventHandlers.forEach((item: {event: string; handler: any}) => {
      mediaElement.removeEventListener(item.event, item.handler);
    });

    this.resetMedia();
  }

  public onBuffering() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const {currentTime} = this.mediaElement;

    // this handler may be called multiple times
    // for one actual buffering-event occuring so lets guard from
    // triggering this event redundantly.
    if (this.isBuffering || (this.isPaused && !this.isSeeking)) {
      return;
    }

    if (this.isSeeking) {
      this.eventCallback(Event.SEEK, {
        currentTime,
      });
    } else {
      this.eventCallback(Event.START_BUFFERING, {
        currentTime,
      });
    }
    this.isBuffering = true;
  }

  public onPaused(currentTime?: number) {
    if (this.isPaused) {
      return;
    }
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    if (!currentTime) {
      currentTime = this.mediaElement.currentTime;
    }

    this.eventCallback(Event.PAUSE, {
      currentTime,
    });

    this.isPaused = true;
  }

  public registerWindowEvents() {
    window.addEventListener('beforeunload', this.onPageClose.bind(this));
    window.addEventListener('unload', this.onPageClose.bind(this));
  }

  public onPageClose() {
    if (!this.onBeforeUnLoadEvent) {
      this.onBeforeUnLoadEvent = true;
      const mediaElement = this.mediaElement;
      let currentTime: number | undefined;
      if (mediaElement != null) {
        currentTime = mediaElement.currentTime;
      }
      this.eventCallback(Event.UNLOAD, {
        currentTime,
      });
    }
  }

  public checkPlayheadProgress() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const mediaElement = this.mediaElement;
    if (mediaElement.paused) {
      this.onPaused();
    }

    clearTimeout(this.bufferingTimeout);

    this.bufferingTimeout = window.setTimeout(() => {
      if (mediaElement.paused || (mediaElement.ended && !this.isBuffering)) {
        return;
      }

      const timeDelta = mediaElement.currentTime - this.previousMediaTime;

      if (timeDelta < HTML5InternalAdapter.BUFFERING_TIMECHANGED_TIMEOUT) {
        this.onBuffering();
      }
    }, HTML5InternalAdapter.BUFFERING_TIMECHANGED_TIMEOUT);
  }

  /**
   * @param {boolean} silent
   */
  public checkQualityLevelAttributes(silent = false) {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    const mediaElement = this.mediaElement;

    const qualityLevelInfo = this.getCurrentQualityLevelInfo();
    if (!qualityLevelInfo) {
      return;
    }

    const {bitrate, width, height} = qualityLevelInfo;

    const isLive = this.isLive();

    // Detect a change of the isLive status and update the medaData of the SM
    if (isLive !== this.lastIsLiveStatus) {
      this.lastIsLiveStatus = isLive;

      // TODO Call event for ISLIVE_CHANGE (similar to VIDEO_CHANGE)
      //      The updateMetadata is obsolete, as we introduced the getCurrentPlaybackInfo
      //      (we don't notify the statemachine about state changes, instead we poll the adapter for the information)
      // if (!silent) {
      //   this.stateMachine.updateMetadata();
      // }
    }

    if (bitrate != null && this.shouldAllowVideoQualityChange(bitrate)) {
      this.setPreviousVideoBitrate(bitrate);
      const eventData = {
        width,
        height,
        bitrate,
        currentTime: mediaElement.currentTime,
      };

      if (!silent) {
        this.eventCallback(Event.VIDEO_CHANGE, eventData);
      }
    }
  }

  public sourceChange(config: any, timestamp: number) {
    this.stateMachine.sourceChange(config, timestamp, this.mediaElement ? this.mediaElement.currentTime : undefined);
  }

  public getSelectedSubtitleFromMediaElement(mediaElement: any): SubtitleInfo | undefined {
    if (mediaElement.textTracks == null) {
      return undefined;
    }
    const textTrackList = mediaElement.textTracks;
    for (const attr of textTrackList) {
      if (attr.mode != null && attr.mode === 'showing') {
        const isSubtitleDisplayed = attr.language != null && attr.language.length > 0;
        return {
          enabled: isSubtitleDisplayed,
          language: isSubtitleDisplayed ? attr.language : undefined,
        };
      }
    }
    return {
      enabled: false,
    };
  }

  private checkSeeking() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const {currentTime} = this.mediaElement;

    const now = Date.now();
    const mediaTimeDif =
      this.previousMediaTime < currentTime
        ? currentTime - this.previousMediaTime
        : this.previousMediaTime - currentTime;
    const clientTimeDif = (now - this.previousClientTime) / 1000;

    /**
     * if the media difference between the previous one and the current one is much higher than
     * the difference of the previous and current client time than there is a seek happening
     */
    if (mediaTimeDif > clientTimeDif * 2) {
      this.isSeeking = true;
      this.onPaused(this.previousMediaTime + clientTimeDif);
    }

    this.previousClientTime = Date.now();
  }
}
