import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';

export class HlsSpeedMeterAdapter {
  private meter: DownloadSpeedMeter;

  constructor(hls: any, meter: DownloadSpeedMeter) {
    this.meter = meter;
    hls.on(hls.constructor.Events.FRAG_LOADED, (eventName, data) => this.handleFragmentLoadedEvent(data));
  }

  public getDownloadSpeedMeter(): DownloadSpeedMeter {
    return this.meter;
  }

  public handleFragmentLoadedEvent(data: {frag: any; stats: any; networkDetails: any}) {
    if (data.frag.type !== 'main') {
      return;
    }
    // See hls documentation https://github.com/video-dev/hls.js/blob/master/src/types/loader.ts#L35
    this.meter.addMeasurement({
      duration: (data.stats.tload - data.stats.trequest) / 1000,
      size: data.stats.total,
      timeToFirstByte: (data.stats.tfirst - data.stats.trequest) / 1000,
      timestamp: new Date(),
      httpStatus: data.networkDetails.status,
    });
  }
}
