import Timespan from '../types/Timespan';

enum TimespanOverlappingType {
  None,
  Overlapping,
  FirstContainedInSecond,
  SecondContainedInFirst,
}

export default class VideoCompletionTracker {
  private watched: Timespan[] = [];
  private videoDuration: number | null = null;

  public reset(): void {
    this.watched = [];
  }

  /**
   * sets video Duration
   * @param videoDuration in sec
   */
  public setVideoDuration(videoDuration: number): void {
    this.videoDuration = videoDuration * 1000;
  }

  /**
   * Adds the watched timespan to the tracker
   * @param watched watched timespan you want to add to the tracker
   * @returns how much time(video parts which have not been seen yet) was added to the tracker
   */
  public addWatched(watched: Timespan): number {
    if (this.videoDuration == null) {
      throw new Error('no video duration set for completion tracker');
    }

    this.watched.push(watched);
    const overlappingTimespan = this.mergeWatched(this.watched);
    return (this.getDuration(watched) - overlappingTimespan) / this.videoDuration;
  }

  /**
   * returns the relative value of how much the user watch the content of the video
   */
  public getCompletionPercentage(): number {
    if (this.videoDuration == null) {
      throw new Error('no video duration set for completion tracker');
    }

    let watched = 0;
    for (const w of this.watched) {
      watched += this.getDuration(w);
    }
    return watched / this.videoDuration;
  }

  /**
   * Recursive Method to merge All Watched Blocks
   * @param watched
   *
   * @returns how much timespan was overlapping while merging the watchBlocks
   */
  private mergeWatched(watched: Timespan[]): number {
    for (let i = 0; i < watched.length; i++) {
      const time1 = watched[i];
      for (let k = i + 1; k < watched.length; k++) {
        const time2 = watched[k];
        let overlappingTimespan = 0;
        const overlapType = this.getOverlappingType(time1, time2);

        if (overlapType === TimespanOverlappingType.Overlapping) {
          this.mergeAndReplace(time1, i, time2, k);
          overlappingTimespan = this.getOverlappingTimespan(time1, time2);
        } else if (overlapType === TimespanOverlappingType.FirstContainedInSecond) {
          this.watched.splice(i, 1);
          overlappingTimespan = this.getDuration(time1);
        } else if (overlapType === TimespanOverlappingType.SecondContainedInFirst) {
          this.watched.splice(k, 1);
          overlappingTimespan = this.getDuration(time2);
        }
        if (overlapType !== TimespanOverlappingType.None) {
          return overlappingTimespan + this.mergeWatched(this.watched);
        }
      }
    }
    return 0;
  }

  private getOverlappingType(first: Timespan, second: Timespan): TimespanOverlappingType {
    if (
      (this.isMomentInTimespan(first.end, second) && first.start < second.start) ||
      (this.isMomentInTimespan(first.start, second) && first.end > second.end)
    ) {
      return TimespanOverlappingType.Overlapping;
    } else if (this.isMomentInTimespan(first.start, second) && this.isMomentInTimespan(first.end, second)) {
      return TimespanOverlappingType.FirstContainedInSecond;
    } else if (this.isMomentInTimespan(second.start, first) && this.isMomentInTimespan(second.end, first)) {
      return TimespanOverlappingType.SecondContainedInFirst;
    }
    return TimespanOverlappingType.None;
  }

  private isMomentInTimespan(moment: number, timespan: Timespan): boolean {
    return moment >= timespan.start && moment < timespan.end;
  }

  private mergeAndReplace(first: Timespan, firstIndex: number, second: Timespan, secondIndex: number): void {
    const end = first.end > second.end ? first.end : second.end;
    const start = first.start < second.start ? first.start : second.start;
    const newWatchedTimespan: Timespan = {start, end};
    this.watched.splice(firstIndex, 1, newWatchedTimespan);
    this.watched.splice(secondIndex, 1);
  }

  private getOverlappingTimespan(first: Timespan, second: Timespan): number {
    const end = first.end < second.end ? first.end : second.end;
    const start = first.start >= second.start ? first.start : second.start;
    return end - start;
  }

  private getDuration(watched: Timespan): number {
    return Math.abs(watched.end - watched.start);
  }
}
