import {InternalAdapterAPI} from '../adapters/internal/InternalAdapterAPI';
import {ErrorCode} from '../enums/ErrorCode';
import {PlayerSize} from '../enums/PlayerSize';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {CastClientConfig} from '../types/CastClientConfig';
import * as EventData from '../types/EventData';
import {Sample} from '../types/Sample';
import Timespan from '../types/Timespan';
import {CodecHelper} from '../utils/CodecHelper';
import {logger} from '../utils/Logger';
import * as Settings from '../utils/Settings';
import * as Utils from '../utils/Utils';
import {VERSION} from '../Version';
import {AdAnalytics} from './AdAnalytics';
import {Backend} from './Backend';
import {BackendFactory} from './BackendFactory';
import {NoOpBackend} from './NoOpBackend';
import {SessionPersistenceHandler} from './SessionPersistenceHandler';

export class Analytics {
  public get version(): string {
    return VERSION;
  }
  public static version: string = VERSION;
  public static LICENSE_CALL_PENDING_TIMEOUT = 200;
  public static PAGE_LOAD_TYPE_TIMEOUT = 200;
  public static CAST_RECEIVER_CONFIG_MESSAGE = 'CAST_RECEIVER_CONFIG_MESSAGE';

  public pageLoadTime: number = 0;
  public playerStartupTime: number = 0;
  public videoStartupTime: number = 0;
  public autoplay: boolean | undefined = undefined;
  public sample: Sample;
  public backend!: Backend;

  private config: AnalyticsConfig;
  private sessionHandler: SessionPersistenceHandler;
  private droppedSampleFrames: number = 0;
  private startupTime: number = 0;
  private adapter!: InternalAdapterAPI;
  private backendFactory: BackendFactory;
  private resetQualityChangeCountIntervalId?: number;

  constructor(
    config: AnalyticsConfig,
    adapter: InternalAdapterAPI,
    backendFactory: BackendFactory = new BackendFactory()
  ) {
    this.config = this.buildDefaultAnalyticsConfigValues(config);

    this.adapter = adapter;
    this.backendFactory = backendFactory;
    this.sessionHandler = new SessionPersistenceHandler(config);

    const licenseKeyReceivedUnsubscriber = this.adapter.onLicenseKeyReceived.subscribe(
      (eventArgs: {licenseKey: string}) => {
        if (!this.config.key) {
          this.config.key = eventArgs.licenseKey;
        }
        licenseKeyReceivedUnsubscriber();
      }
    );

    this.sample = this.setupSample();
    this.init();
    this.setupStateMachineCallbacks();
    this.adapter.initialize();

    if (this.adapter.adModule) {
      const adAnalytics = new AdAnalytics(this, this.adapter.adModule);
    }

    this.checkForErrorsInConfig(config);
  }

  public getPlayerInformationFromAdapter() {
    const player = this.config.player || this.adapter.getPlayerName();
    return {
      player,
      version: player + '-' + this.adapter.getPlayerVersion(),
      playerTech: this.adapter.getPlayerTech(),
    };
  }

  public updateSamplesToCastClientConfig(samples: Sample[], castClientConfig: CastClientConfig) {
    for (const sample of samples) {
      this.updateSampleToCastClientConfig(sample, castClientConfig);
    }
  }

  public updateSampleToCastClientConfig(sample: Sample, castClientConfig: CastClientConfig) {
    const {config, userId, impressionId, domain, path, language, userAgent} = castClientConfig;
    sample.impressionId = impressionId;
    sample.userId = userId;
    sample.userAgent = userAgent;
    sample.domain = domain;
    sample.path = path;
    sample.language = language;

    this.setConfigParameters(sample, config);
  }

  public init() {
    if (
      this.adapter.supportsDeferredLicenseLoading !== true &&
      (this.config.key === '' || !Utils.validString(this.config.key))
    ) {
      logger.error('Invalid analytics license key provided');
      return;
    }

    logger.setLogging((this.config.debug as boolean) || false);

    this.backend = this.createBackend(this.config);
    this.videoStartupTime = 0;

    this.setConfigParameters();

    this.generateNewImpressionId();
    this.setUserId();

    if (this.adapter.videoCompletionTracker) {
      this.adapter.videoCompletionTracker.reset();
    }
    this.clearResetQualityChangeIntverval();
    this.adapter.resetQualityChangeCount();
  }

  public disable() {
    this.backend = new NoOpBackend();
  }

  public setConfigParameters(sample = this.sample, config = this.config) {
    sample.key = config.key;
    sample.playerKey = config.playerKey;
    if (config.player) {
      sample.player = config.player;
    }
    sample.domain = this.getDomainFromConfig(config) || Utils.sanitizePath(window.location.hostname);
    sample.cdnProvider = config.cdnProvider;
    sample.videoId = config.videoId;
    sample.videoTitle = config.title;
    sample.customUserId = config.userId != null ? config.userId : config.customUserId;

    sample.customData1 = Utils.getCustomDataString(config.customData1);
    sample.customData2 = Utils.getCustomDataString(config.customData2);
    sample.customData3 = Utils.getCustomDataString(config.customData3);
    sample.customData4 = Utils.getCustomDataString(config.customData4);
    sample.customData5 = Utils.getCustomDataString(config.customData5);
    sample.customData6 = Utils.getCustomDataString(config.customData6);
    sample.customData7 = Utils.getCustomDataString(config.customData7);

    sample.experimentName = config.experimentName;
  }

  public generateNewImpressionId() {
    this.sample.impressionId = Utils.generateUUID();
  }

  public setUserId() {
    this.sample.userId = this.sessionHandler.userId;
  }

  public setupStateMachineCallbacks() {
    // All of these are called in the onLeaveState Method.
    // So it's the last sample
    this.adapter.stateMachineCallbacks.setup = (duration: number, state: string) => {
      logger.log(
        'Setup bitmovin analytics ' + this.sample.analyticsVersion + ' with impressionId: ' + this.sample.impressionId
      );

      this.setDuration(duration);
      this.setState(state);
      this.playerStartupTime = this.sample.playerStartupTime = duration;

      if (window.performance && window.performance.timing) {
        const loadTime = Utils.getCurrentTimestamp() - window.performance.timing.navigationStart;
        this.pageLoadTime = this.sample.pageLoadTime = loadTime;
      }

      this.startupTime = duration;

      this.sendAnalyticsRequestAndClearValues();

      this.sample.pageLoadTime = 0;
    };

    this.adapter.stateMachineCallbacks.startup = (duration: number, state: string) => {
      this.setDuration(duration);
      this.videoStartupTime = this.sample.videoStartupTime = duration;
      this.sample.supportedVideoCodecs = CodecHelper.supportedVideoFormats;
      this.setState(state);

      if (this.startupTime > 0) {
        this.startupTime += duration;
      }
      this.sample.startupTime = this.startupTime;
      this.autoplay = this.sample.autoplay = this.adapter.getAutoPlay();

      const drmPerformance = this.adapter.getDrmPerformanceInfo();
      if (drmPerformance != null) {
        this.sample.drmType = drmPerformance.drmType;
        this.sample.drmLoadTime = drmPerformance.drmLoadTime;
      }

      this.sendAnalyticsRequestAndClearValues();
      this.sample.autoplay = undefined;
    };

    this.adapter.stateMachineCallbacks.playing = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);
      this.sample.played = duration;
      this.setCompletionValues();
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.unload = (duration: number, state: string) => {
      if (state === 'playing') {
        this.setDuration(duration);
        this.setState(state);
        this.sample.played = duration;
        this.setCompletionValues();
        this.sendUnloadRequest();
      }

      if (this.videoStartupTime > 0) {
        this.clearValues();
        this.setState('closed');
        this.sendUnloadRequest();
      }
    };

    this.adapter.stateMachineCallbacks.heartbeat = (
      duration: number,
      state: string,
      sampleData: Pick<Sample, 'played'> | Pick<Sample, 'buffered'>
    ) => {
      this.setState(state);
      this.setDuration(duration);

      this.sample = {
        ...this.sample,
        ...sampleData,
      };

      if (state === 'playing') {
        this.setCompletionValues();
      }

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.qualitychange = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.qualitychange_pause = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.qualitychange_rebuffering = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.videoChange = (event: any) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
      this.setPlaybackVideoPropertiesFromEvent(event);
    };

    this.adapter.stateMachineCallbacks.audioChange = (event: any) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
      this.setPlaybackAudioPropertiesFromEvent(event);
    };

    this.adapter.stateMachineCallbacks.audiotrack_changing = () => {
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.pause = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.paused = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.paused_seeking = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.seeked = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.end_play_seeking = (duration: number, state: string) => {
      this.setState(state);
      this.setDuration(duration);

      this.sample.seeked = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.rebuffering = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.buffered = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.videoStartFailed = (
      event: Readonly<EventData.VideoStartFailedEvent>,
      sendRequest = true
    ) => {
      this.sample.videoStartFailed = true;
      this.sample.videoStartFailedReason = event.reason;
      if (event.errorData != null) {
        this.sample.errorCode = event.errorData.code;
        this.sample.errorMessage = event.errorData.message;
      }
      if (sendRequest) {
        this.sendAnalyticsRequestAndClearValues();
      }
    };

    this.adapter.stateMachineCallbacks.error = (event: Readonly<EventData.ErrorEvent>) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);

      this.setState('error');
      this.sample.errorCode = event.code;
      this.sample.errorMessage = event.message;
      this.sample.errorData = JSON.stringify(event.data);

      const segmentNames = this.adapter.segments.map((s) => s.name);
      this.sample.errorSegments = segmentNames;

      if (this.adapter.onError) {
        this.adapter.onError();
      }

      this.sendAnalyticsRequestAndClearValues();

      delete this.sample.errorCode;
      delete this.sample.errorMessage;
      delete this.sample.errorData;
    };

    this.adapter.stateMachineCallbacks.ad = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);
      this.sample.ad = duration;
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.mute = () => {
      this.sample.isMuted = true;
    };

    this.adapter.stateMachineCallbacks.unMute = () => {
      this.sample.isMuted = false;
    };

    this.adapter.stateMachineCallbacks.subtitle_changing = () => {
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent = (event: any) => {
      if (Utils.validNumber(event.currentTime)) {
        this.sample.videoTimeEnd = Utils.calculateTime(event.currentTime);
      }
    };

    this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent = (event: any) => {
      if (Utils.validNumber(event.currentTime)) {
        this.sample.videoTimeStart = Utils.calculateTime(event.currentTime);
      }
    };

    this.adapter.stateMachineCallbacks.manualSourceChange = (event: {config: AnalyticsConfig}) => {
      this.sample = this.setupSample();
      this.startupTime = 0;
      this.config = event.config ? event.config : this.config;
      this.init();
    };

    this.adapter.stateMachineCallbacks.initialSourceChange = (event: {config: AnalyticsConfig}) => {
      this.config = event.config;
      this.setConfigParameters();
    };

    // The video has ended and we set up for a new impression
    this.adapter.stateMachineCallbacks.end = () => {
      this.sample = this.setupSample();
      this.startupTime = 0;
      this.init();
    };

    this.adapter.stateMachineCallbacks.stop_collecting = () => {
      this.disable();
    };
  }

  public setCustomDataOnce = (values: any) => {
    const oldConfig = this.config;
    this.setCustomData(values);
    this.setCustomData(oldConfig);
  };

  public guardAgainstMissingVideoTitle = (oldConfig: AnalyticsConfig, newConfig: AnalyticsConfig) => {
    if (oldConfig && newConfig && oldConfig.title && !newConfig.title) {
      // TODO: Better description
      logger.error('The new analytics configuration does not contain the field title');
    }
  };

  public guardAgainstMissingIsLive = (oldConfig: AnalyticsConfig, newConfig: AnalyticsConfig) => {
    if (oldConfig != null && newConfig != null && oldConfig.isLive && newConfig.isLive == null) {
      logger.error(
        'The new analytics configuration does not contain the field `isLive`. It will default to `false` which might be unintended? Once stream playback information is available the type will be populated.'
      );
    }
  };

  public sourceChange = (config: AnalyticsConfig) => {
    logger.log('Processing Source Change for Analytics', config);

    this.guardAgainstMissingVideoTitle(this.config, config);
    this.guardAgainstMissingIsLive(this.config, config);

    const newConfig = this.getMergedAnalyticsConfig(config);

    this.adapter.sourceChange(newConfig, Utils.getCurrentTimestamp());
  };

  public setCustomData = (values: any): any => {
    const filterValues = ({
      customData1,
      customData2,
      customData3,
      customData4,
      customData5,
      customData6,
      customData7,
      experimentName,
    }: any) => {
      const retVal = {
        customData1,
        customData2,
        customData3,
        customData4,
        customData5,
        customData6,
        customData7,
        experimentName,
      };
      if (customData1) {
        retVal.customData1 = customData1;
      }
      if (customData2) {
        retVal.customData2 = customData2;
      }
      if (customData3) {
        retVal.customData3 = customData3;
      }
      if (customData4) {
        retVal.customData4 = customData4;
      }
      if (customData5) {
        retVal.customData5 = customData5;
      }
      if (customData6) {
        retVal.customData6 = customData6;
      }
      if (customData7) {
        retVal.customData7 = customData7;
      }
      if (experimentName) {
        retVal.experimentName = experimentName;
      }
      return retVal;
    };

    this.sendAnalyticsRequestAndClearValues();
    this.config = {
      ...this.config,
      ...filterValues(values),
    };
    this.setConfigParameters();
  };

  public getCurrentImpressionId = (): string | undefined => {
    return this.sample.impressionId;
  };

  public setDuration(duration: number) {
    this.sample.duration = duration;
  }

  public setState(state: string) {
    this.sample.state = state;
  }

  public setPlaybackVideoPropertiesFromEvent(event: any) {
    if (Utils.validNumber(event.width)) {
      this.sample.videoPlaybackWidth = event.width;
    }
    if (Utils.validNumber(event.height)) {
      this.sample.videoPlaybackHeight = event.height;
    }
    if (Utils.validNumber(event.bitrate)) {
      this.sample.videoBitrate = event.bitrate;
    }
    if (Utils.validString(event.codec)) {
      this.sample.videoCodec = event.codec;
    }
  }

  public setPlaybackAudioPropertiesFromEvent(event: any) {
    if (Utils.validNumber(event.bitrate)) {
      this.sample.audioBitrate = event.bitrate;
    }
    if (Utils.validString(event.codec)) {
      this.sample.audioCodec = event.codec;
    }
  }

  public setPlaybackInfoFromAdapter() {
    const info = this.adapter.getCurrentPlaybackInfo();
    if (!info) {
      return;
    }

    this.sample.isLive = this.getIsLiveFromConfigOrPlaybackInfo(this.config, info.isLive);

    if (Utils.validString(info.size)) {
      this.sample.size = info.size;
    }
    if (Utils.validString(info.playerTech)) {
      this.sample.playerTech = info.playerTech;
    }
    if (Utils.validNumber(info.videoDuration)) {
      this.sample.videoDuration = Utils.calculateTime(info.videoDuration || 0);
    }
    if (Utils.validString(info.streamFormat)) {
      this.sample.streamFormat = info.streamFormat;
    }
    if (Utils.validString(info.mpdUrl)) {
      this.sample.mpdUrl = info.mpdUrl;
    }
    if (Utils.validString(info.m3u8Url)) {
      this.sample.m3u8Url = info.m3u8Url;
    }
    if (Utils.validString(info.progUrl)) {
      this.sample.progUrl = info.progUrl;
    }
    if (Utils.validNumber(info.videoWindowWidth)) {
      this.sample.videoWindowWidth = info.videoWindowWidth;
    }
    if (Utils.validNumber(info.videoWindowHeight)) {
      this.sample.videoWindowHeight = info.videoWindowHeight;
    }
    if (Utils.validNumber(info.screenHeight)) {
      this.sample.screenHeight = info.screenHeight;
    }
    if (Utils.validNumber(info.screenWidth)) {
      this.sample.screenWidth = info.screenWidth;
    }
    if (Utils.validNumber(info.videoPlaybackHeight)) {
      this.sample.videoPlaybackHeight = info.videoPlaybackHeight;
    }
    if (Utils.validNumber(info.videoPlaybackWidth)) {
      this.sample.videoPlaybackWidth = info.videoPlaybackWidth;
    }
    if (Utils.validNumber(info.videoBitrate)) {
      this.sample.videoBitrate = info.videoBitrate;
    }
    if (Utils.validNumber(info.audioBitrate)) {
      this.sample.audioBitrate = info.audioBitrate;
    }
    if (Utils.validBoolean(info.isMuted)) {
      this.sample.isMuted = info.isMuted;
    }
    if (Utils.validBoolean(info.isCasting)) {
      this.sample.isCasting = info.isCasting;
    }
    if (Utils.validString(info.videoTitle) && !this.config.title) {
      this.sample.videoTitle = info.videoTitle;
    }
    if (Utils.validString(info.audioCodec)) {
      this.sample.audioCodec = info.audioCodec;
    }
    if (Utils.validString(info.videoCodec)) {
      this.sample.videoCodec = info.videoCodec;
    }
    if (Utils.validString(info.audioLanguage)) {
      this.sample.audioLanguage = info.audioLanguage;
    }
    if (Utils.validBoolean(info.subtitleEnabled)) {
      this.sample.subtitleEnabled = info.subtitleEnabled;
    }
    if (Utils.validString(info.subtitleLanguage)) {
      this.sample.subtitleLanguage = info.subtitleLanguage;
    } else {
      this.sample.subtitleLanguage = undefined;
    }
    if (Utils.validNumber(info.droppedFrames)) {
      this.sample.droppedFrames = Math.max(info.droppedFrames - this.droppedSampleFrames, 0);
      this.droppedSampleFrames = info.droppedFrames;
    }
  }

  public setupSample(): Sample {
    this.droppedSampleFrames = 0;

    return {
      platform: 'web',
      playerStartupTime: 0,
      pageLoadType: Utils.getPageLoadType(),
      path: Utils.sanitizePath(window.location.pathname),
      language: navigator.language || (navigator as any).userLanguage,
      userAgent: navigator.userAgent,
      screenWidth: screen.width,
      screenHeight: screen.height,
      isLive: false,
      videoDuration: 0,
      size: PlayerSize.Window,
      time: 0,
      videoWindowWidth: 0,
      videoWindowHeight: 0,
      droppedFrames: 0,
      played: 0,
      buffered: 0,
      paused: 0,
      ad: 0,
      seeked: 0,
      videoPlaybackWidth: 0,
      videoPlaybackHeight: 0,
      videoBitrate: 0,
      audioBitrate: 0,
      videoTimeStart: 0,
      videoTimeEnd: 0,
      videoStartupTime: 0,
      duration: 0,
      startupTime: 0,
      analyticsVersion: VERSION,
      pageLoadTime: 0,
      completedTotal: 0,
      ...this.getPlayerInformationFromAdapter(),
    };
  }

  public sendAnalyticsRequest() {
    this.setPlaybackInfoFromAdapter();
    this.sample.time = Utils.getCurrentTimestamp();
    this.sample.downloadSpeedInfo = this.adapter.downloadSpeedInfo;

    const copySample = {...this.sample};
    this.backend.sendRequest(copySample);
  }

  public sendAnalyticsRequestAndClearValues() {
    this.sendAnalyticsRequest();
    this.clearValues();
  }

  public sendUnloadRequest() {
    this.backend.sendUnloadRequest(this.sample);
  }

  public sendAnalyticsRequestSynchronous() {
    this.backend.sendRequestSynchronous(this.sample);
  }

  public clearValues() {
    this.sample.ad = 0;
    this.sample.paused = 0;
    this.sample.played = 0;
    this.sample.seeked = 0;
    this.sample.buffered = 0;

    this.sample.playerStartupTime = 0;
    this.sample.videoStartupTime = 0;
    this.sample.startupTime = 0;

    this.sample.duration = 0;
    this.sample.droppedFrames = 0;

    this.sample.drmLoadTime = undefined;

    this.sample.videoStartFailed = undefined;
    this.sample.videoStartFailedReason = undefined;

    this.sample.completed = undefined;
    this.adapter.clearValues();
  }

  public getIsLiveFromConfigOrPlaybackInfo(config: AnalyticsConfig, isLiveFromPlayback?: boolean): boolean {
    if (isLiveFromPlayback == null) {
      return config.isLive || false;
    }
    return Utils.validBoolean(isLiveFromPlayback) ? isLiveFromPlayback : false;
  }

  public getMergedCollectorConfig(newConfig: AnalyticsConfig, oldConfig = this.config) {
    let mergedCollectorConfig = (oldConfig || {}).config;
    if (newConfig != null) {
      mergedCollectorConfig = {...mergedCollectorConfig, enabled: true, ...newConfig.config};
    }
    return mergedCollectorConfig;
  }

  public getMergedAnalyticsConfig(newConfig: AnalyticsConfig, oldConfig = this.config): AnalyticsConfig {
    const mergedCollectorConfig = this.getMergedCollectorConfig(newConfig, oldConfig);
    const mergedConfig = {
      ...oldConfig,
      ...newConfig,
      ...{config: mergedCollectorConfig},
    };
    return mergedConfig;
  }

  private buildDefaultAnalyticsConfigValues(config: AnalyticsConfig): AnalyticsConfig {
    if (!Utils.validBoolean(config.isLive)) {
      config.isLive = false;
    }
    return config;
  }

  private checkForErrorsInConfig(config: AnalyticsConfig) {
    if (config.customUserId != null && config.userId != null) {
      logger.warning(
        'Configuration Warning: \nCustomUserId and UserId are set in the config \nValue of UserId will be used in sample \nPlease only use one configuartion field to set your userId'
      );
    }
  }

  private getDomainFromConfig(config: AnalyticsConfig) {
    const collectorConfig = config.config;
    return collectorConfig != null && collectorConfig.origin != null ? collectorConfig.origin : undefined;
  }

  private createBackend(config: AnalyticsConfig): Backend {
    const domainFromConfig = this.getDomainFromConfig(config);
    const domain = domainFromConfig || Utils.sanitizePath(window.location.hostname);
    return this.backendFactory.createBackend(config, {key: config.key, domain, version: VERSION}, this.adapter);
  }

  private setCompletionValues() {
    if (this.adapter.videoCompletionTracker) {
      const completed = this.adapter.videoCompletionTracker.addWatched({
        start: this.sample.videoTimeStart,
        end: this.sample.videoTimeEnd,
      } as Timespan);
      const completedTotal = this.adapter.videoCompletionTracker.getCompletionPercentage();

      if (!Number.isNaN(completed) && !Number.isNaN(completedTotal)) {
        this.sample.completed = completed;
        this.sample.completedTotal = completedTotal;
      }
    }
  }

  private sendQualityChange(state: string, duration: number): void {
    if (this.resetQualityChangeCountIntervalId == null) {
      this.resetQualityChangeCountIntervalId = window.setInterval(() => {
        this.adapter.resetQualityChangeCount();
      }, Settings.ANALYTICS_QUALITY_CHANGE_COUNT_RESET_INTERVAL);
    }

    this.adapter.increaseQualityChangeCount();
    if (!this.adapter.isQualityChangeEventEnabled()) {
      this.setDuration(duration);
      this.adapter.stateMachineCallbacks.error({
        code: ErrorCode.QualityChangeThresholdExceeded,
        message: 'ANALYTICS_QUALITY_CHANGE_THRESHOLD_EXCEEDED',
        data: undefined,
        currentTime: undefined,
      });
    } else {
      this.setDuration(duration);
      this.setState(state);
      this.sendAnalyticsRequestAndClearValues();
    }
  }

  private clearResetQualityChangeIntverval() {
    if (this.resetQualityChangeCountIntervalId != null) {
      window.clearInterval(this.resetQualityChangeCountIntervalId);
      delete this.resetQualityChangeCountIntervalId;
    }
  }
}
