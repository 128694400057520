import {AnalyticsConfig} from './AnalyticsConfig';
import * as EventData from './EventData';
import {Sample} from './Sample';

export interface StateMachineCallbacks {
  setup: (duration: number, state: string) => void;
  startup: (duration: number, state: string) => void;
  playing: (duration: number, state: string) => void;
  heartbeat: (duration: number, state: string, payload: Pick<Sample, 'played'> | Pick<Sample, 'buffered'>) => void;
  qualitychange: (duration: number, state: string) => void;
  qualitychange_pause: (duration: number, state: string) => void;
  qualitychange_rebuffering: (duration: number, state: string) => void;
  videoChange: (event: any) => void;
  audioChange: (event: any) => void;
  audiotrack_changing: () => void;
  pause: (duration: number, state: string) => void;
  paused_seeking: (duration: number, state: string) => void;
  end_play_seeking: (duration: number, state: string) => void;
  rebuffering: (duration: number, state: string) => void;
  error: (event: Readonly<EventData.ErrorEvent>) => void;
  end: (duration: number, state: string) => void;
  unload: (duration: number, state: string) => void;
  ad: (duration: number, state: string) => void;
  mute: () => void;
  unMute: () => void;
  subtitle_changing: () => void;
  setVideoTimeEndFromEvent: (event: any) => void;
  setVideoTimeStartFromEvent: (event: any) => void;
  videoStartFailed: (event: Readonly<EventData.VideoStartFailedEvent>, sendRequest: boolean) => void;
  source_changing: (duration: number, state: string, event: any) => void;
  ready: (duration: number, state: string, event: any) => void;
  startCasting: (duration: number, event: any) => void;
  manualSourceChange: (event: {config: AnalyticsConfig}) => void;
  initialSourceChange: (event: {config: AnalyticsConfig}) => void;
  muting_ready: (duration: number, state: string, event: any) => void;
  stop_collecting: () => void;
}

// tslint:disable:no-unused-expression
export const defaultStateMachineCallbacks: StateMachineCallbacks = {
  setup: (time: number, state: string) => void {},
  startup: (time: number, state: string) => void {},
  playing: (time: number, state: string) => void {},
  heartbeat: (time: number, state: string, payload: Pick<Sample, 'played'> | Pick<Sample, 'buffered'>) => void {},
  qualitychange: (time: number, state: string) => void {},
  qualitychange_pause: (time: number, state: string) => void {},
  qualitychange_rebuffering: (time: number, state: string) => void {},
  videoChange: (event: any) => void {},
  audioChange: (event: any) => void {},
  audiotrack_changing: () => void {},
  pause: (time: number, state: string) => void {},
  paused_seeking: (time: number, state: string) => void {},
  end_play_seeking: (time: number, state: string) => void {},
  rebuffering: (time: number, state: string) => void {},
  error: (event: Readonly<EventData.ErrorEvent>) => void {},
  end: (time: number, state: string) => void {},
  unload: (time: number, state: string) => void {},
  ad: (time: number, state: string) => void {},
  mute: () => void {},
  unMute: () => void {},
  subtitle_changing: () => void {},
  setVideoTimeEndFromEvent: (event: any) => void {},
  setVideoTimeStartFromEvent: (event: any) => void {},
  videoStartFailed: (event: Readonly<EventData.VideoStartFailedEvent>, sendRequest: boolean) => void {},
  source_changing: (time: number, state: string, event: any) => void {},
  ready: (time: number, state: string, event: any) => void {},
  startCasting: (time: number, event: any) => void {},
  manualSourceChange: (event: {config: AnalyticsConfig}) => void {},
  initialSourceChange: (event: {config: AnalyticsConfig}) => void {},
  muting_ready: (time: number, state: string, event: any) => void {},
  stop_collecting: () => void {},
};
